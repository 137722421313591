import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  view: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  viewInner: {
    alignItems: 'left',
    width: 350,
    padding: 15
  },
  header: {
    fontWeight: 'bold',
    fontSize: 25,
  },
  text: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    borderRadius: 5
  },
  inputUneditable: {
    backgroundColor: "#FFF",
    borderColor: "#999",
    color: "#999",
    cursor: 'auto',
  },
  linkText: {
    color: '#2e78b7',
    fontSize: 14,
  },
  warningMessage: {
    color: 'red',
    frontSize: 14,
  },

  // Biotest sub-item title
  titleShell: {
    backgroundColor: "#0C7036",

    justifyContent: 'center',
    padding: 4,
    marginTop: 10,
    marginBottom: 10,

    borderRadius: 5,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    color: "white",

    padding: 10,
    borderColor: "white",
    borderTopWidth: 3,
    borderBottomWidth: 3,
    borderLeftWidth: 3,
    borderRadius: 5,
  },

  // simluate default button
  button: {
    backgroundColor: "#219700",
    borderRadius: 3,
    padding: 10,
    margin: 10,
    // elevation: 2,

    minWidth: 80,

    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 0
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },

  passiveButtion: {
    backgroundColor: "#AAA",
  },

  buttonText: {
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
  },

  iconButton: {
    margin: 5,

    color: "#219700",
    textShadowColor: "#BBB",
    textShadowOffset: {
      width: 2,
      height: 0
    },
    textShadowOpacity: 0.25,
    textShadowRadius: 4,
    elevation: 5,
  },
});

export default styles;

import React, { useEffect, useState } from 'react';

import config from './config';

import { AuthContext, IsAdminContext, LanguageContext } from './context';

import SecureScreens from './secure';
import AuthScreens from './auth';
import LoadingScreen from './screens/loading';

const Main = () => {
  const [isLoadingWait, setIsLoadingWait] = useState(true);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [userToken, setUserToken] = useState(null);
  const setAdminFlag = React.useContext(IsAdminContext).setAdminFlag;
  const languages = React.useContext(LanguageContext);

  const setUser = (loadedLanguages, adminFlag, token) => {
    languages.set(loadedLanguages);
    setAdminFlag(adminFlag);
    setUserToken(token); // maybe sessionId?
  }

  const loadLanguages = (language, adminFlag, token) => {
    fetch(`${config.api_prefix}/language/code/${language}`, { credentials: 'include' })
      .then(response => response.json())
      .then(json => {
        if(json.code == 200){
          setUser([json.data.code, ...json.data.priority], adminFlag, token);
        }
        else {
          // Show message
          console.log('Server Error');
        }
      })
      .catch(error => console.error(error))
      .finally(() => setIsLoadingUser(false));
  }

  const isLogined = () => {
    let loginedFlag = false;
    fetch(`${config.api_prefix}/user/me`, { credentials: 'include' })
      .then(response => response.json())
      .then(json => {
        if(json.code == 200){
          console.log("login-ed");
          loginedFlag = true;
          loadLanguages(json.data.language, json.data.isAdmin, 'fake token');
        }
        else {
          // Show message
          console.log('403 Unauthorised');
        }
      })
      .catch(error => console.error(error))
      .finally(() => loginedFlag ? console.log('waiting language loading') : setIsLoadingUser(false));
  };

  useEffect( () => {
    isLogined();
    setTimeout( () => {
      setIsLoadingWait(false);
    }, 500)
  }, []);

  if(isLoadingWait || isLoadingUser) {
    return <LoadingScreen />;
  }

  return ( userToken ? <SecureScreens /> :
    <AuthContext.Provider value={setUserToken}>
      <AuthScreens />
    </AuthContext.Provider>
  );
}

export default Main;

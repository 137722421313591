let ScreensContextInit = {
  _: {},
  biotest: {
    create: {},
  },
  fitnessClient: {
    profile: {},
  },
  user: {
  	dashboard: {},
    profile: {},
  },
};

export default ScreensContextInit;
import React, { useEffect, useState } from 'react';
import { View, Pressable, Text, TextInput } from 'react-native';
import LanguageResourceLoader from '../../language';

import config from '../../config';
import styles from '../../styles';
import { LanguageContext, ScreensContext } from '../../context';

import ConfirmationBox from '../../components/ConfirmationBox';
import SelectMenu from '../../components/SelectMenu';
import SelectView from '../../components/SelectView';

const langLoader = new LanguageResourceLoader('user', ['en', 'zh']);

const AddUserScreen = ({ route, navigation }) => {
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  const [roleSelectOption, setRoleSelectOption] = useState([
    { id: "default", value: "Select role" },
  ]);
  const [role, setRole] = useState(0);

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [surname, setSurname] = useState('');
  const [givename, setGivename] = useState('');
  const [company, setCompany] = useState('');

  const genderSelectOption = [
    { value: langLoader.get('genderDefaultSelect') },
    { value: "M" },
    { value: "F" },
  ];
  const [gender, setGender] = useState(0);

  const [message, setMessage] = useState(null);

  let o={}; // temp
  const confirm = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const genderSelect = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const roleSelect = ( o = {}, [o.visible, o.setVisible] = useState(false), o);

  const userDashboardScreenContext = React.useContext(ScreensContext).user?.dashboard;

  const getRoles = () => {
    fetch(`${config.api_prefix}/role`, { credentials: 'include' })
      .then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          let loadedRoles = [];

          json.data.forEach( role => loadedRoles.push({
            id: role._id,
            value: role.name,
          }) );

          setRoleSelectOption([
            ...roleSelectOption,
            ...loadedRoles,
          ]);
        }
        else {
          // Show message
          console.log('invalid role Id');
        }
      })
      .catch((error) => console.error(error));
  };

  const createUser = () => {
    const data = {
      roleId: roleSelectOption[role].id,
      email,
      username,
      password,
      ...(phoneNumber && { phoneNumber }),
      ...(surname && givename && {
        name: {
          surname,
          givename,
        },
      }),
      ...(gender && { gender: genderSelectOption[gender].value }),
      ...(company && { company }),
    };

    if( (surname == '' || givename == '') && !(surname == '' && givename == '')) // surname xor givename
      return setMessage(langLoader.get('messageNeedNames'));

    fetch(`${config.api_prefix}/user`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({...data, language: languages.get[0]}),
    }).then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          if (typeof userDashboardScreenContext?.refresh == 'function')  userDashboardScreenContext.refresh();
          confirm.setVisible(true);
        }
        else {
          // Show message
          setMessage(`Error ${json.code}: ${json.message}`);
        }
      })
      .catch((error) => console.error(error));
  };

  const submitForm = createUser;

  useEffect( () => {
    getRoles();
  }, []);

  return (
    <View style={styles.view}>
      <ConfirmationBox
        visible={confirm}
        title={langLoader.get('createUserConfirmMessage')}
        data={[
          {value: langLoader.get('confirmButton'), color: "#219700"},
        ]}
        callback={ () => navigation.goBack() }
        defaultIndex={0}
      />
      <SelectMenu
        visible={roleSelect}
        data={roleSelectOption}
        callback={setRole}
        selectedIndex={role}
      />
      <SelectMenu
        visible={genderSelect}
        data={genderSelectOption}
        callback={setGender}
        selectedIndex={gender}
      />
      <View style={styles.viewInner}>
        <Text>{langLoader.get('role')}</Text>
        <SelectView
          value={roleSelectOption[role].value}
          onPress={() => roleSelect.setVisible(true)}
          selecting={roleSelect.visible}
        />
        <Text>{langLoader.get('email')}</Text>
        <TextInput
          style={styles.input}
          autoComplete="email"
          onChange={ e => setEmail(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('username')}</Text>
        <TextInput
          style={styles.input}
          autoComplete="username"
          onChange={ e => setUsername(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('password')}</Text>
        <TextInput
          style={styles.input}
          autoComplete="password"
          secureTextEntry={true}
          onChange={ e => setPassword(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('surname')}</Text>
        <TextInput
          value={surname}
          style={styles.input}
          autoComplete="name-family"
          onChange={ e => setSurname(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('givename')}</Text>
        <TextInput
          value={givename}
          style={styles.input}
          autoComplete="name-given"
          onChange={ e => setGivename(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('company')}</Text>
        <TextInput
          value={company}
          style={styles.input}
          autoComplete="company"
          onChange={ e => setCompany(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('mobile')}</Text>
        <TextInput
          value={phoneNumber}
          style={styles.input}
          autoComplete="tel"
          onChange={ e => setPhoneNumber(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('gender')}</Text>
        <SelectView
          value={genderSelectOption[gender].value}
          onPress={() => genderSelect.setVisible(true)}
          selecting={genderSelect.visible}
        />

        <Text style={styles.warningMessage} >{message}</Text>
        <Pressable
          style={({ pressed }) => [styles.button, { opacity: pressed ? 0.2 : 1 }]}
          onPress={submitForm}
        >
          <Text style={styles.buttonText}>{langLoader.get('submitFormButton') ? langLoader.get('submitFormButton').toUpperCase() : ''}</Text>
        </Pressable>
      </View>
    </View>
  );
};

export default AddUserScreen;

import { View, Text } from 'react-native';

import styles from '../styles';

const ForgotPasswordScreen = props => {
  return (
    <View style={styles.view}>
      <Text>Please contact Administrator to reset password</Text>
    </View>
  );
};

export default ForgotPasswordScreen;

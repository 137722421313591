import * as React from 'react';
import { StyleSheet, View, Pressable, Text } from 'react-native';
import LanguageResourceLoader from '../language';

import styles from '../styles';
import { LanguageContext } from '../context';

const langLoader = new LanguageResourceLoader('biotest', ['en', 'zh']);

const BiotestHomeScreen = ({ navigation }) => {
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  return (
    <View style={styles.view}>
      <View style={styles.viewInner}>
        <Pressable
          style={({ pressed }) => [styles.button, biotestHomeStyles.bigButton, { opacity: pressed ? 0.2 : 1 }]}
          onPress={ () => navigation.navigate('CreateBiotestStack') }
        >
          <Text style={[styles.buttonText, biotestHomeStyles.bigButtonText]}>{langLoader.get('newTestButton')}</Text>
        </Pressable>
        <Pressable
          style={({ pressed }) => [styles.button, biotestHomeStyles.bigButton, { opacity: pressed ? 0.2 : 1 }]}
          onPress={ () => navigation.navigate('BiotestHistoryStack') }
        >
          <Text style={[styles.buttonText, biotestHomeStyles.bigButtonText]}>{langLoader.get('historyButton')}</Text>
        </Pressable>
      </View>
    </View>
  );
};

const biotestHomeStyles = StyleSheet.create({
  bigButton: {
    height:200,
    justifyContent: 'center',
    alignItems: 'center',

    marginBottom: 40,
    marginTop: 40,
  },
  bigButtonText: {
    fontSize: 42
  }
});

export default BiotestHomeScreen;

import React, { useState } from 'react';
import { StyleSheet, View, Pressable, Text } from 'react-native';

const Switch = ({
  callback,
  data = [
    "Yes",
    "No",
  ],
  defaultIndex = null,
}) => {
  const [selecting, setSelecting] = useState(null);

  const back = (e, value) => {
    (typeof callback) === "function" ? callback(value): 0;
  }

  return (
    <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
      <Pressable
        style={[styles.switchItem, selecting == 0 ? { borderWidth: 2 } : {}]}
        onPress={e => back(e, 0)}
        onPressIn={ () => setSelecting(0) }
        onPressOut={ () => setSelecting(null) }
      >
        <Text style={{ marginRight: 10, minWidth: 16 }}>{ defaultIndex == 0 ? '⦿':'◯' }</Text>
        <Text style={styles.switchItemText}>{ data[0] }</Text>
      </Pressable>
      <Pressable
        style={[styles.switchItem, selecting == 1 ? { borderWidth: 2 } : {}]}
        onPress={e => back(e, 1)}
        onPressIn={ () => setSelecting(1) }
        onPressOut={ () => setSelecting(null) }
      >
        <Text style={{ marginRight: 10, minWidth: 16 }}>{ defaultIndex == 1 ? '⦿':'◯' }</Text>
        <Text style={styles.switchItemText}>{ data[1] }</Text>
      </Pressable>
    </View>
  )
};

const styles = StyleSheet.create({
  switchItem: {
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    margin: 12,
    elevation: 2,

    width:'initial',
    minWidth: 100,
    height: 40,
  },
  switchItemText: {
  },
});

export default Switch;
import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import LanguageResourceLoader from '../language';

import config from '../config';
import styles from '../styles';
import { LanguageContext } from '../context';

import SelectMenu from '../components/SelectMenu';
import SelectView from '../components/SelectView';

const langLoader = new LanguageResourceLoader('language', ['en', 'zh']);

const LanguageScreen = ({ title }) => {
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  const appLanguageSelectOption = [
    {
      name: "en",
      value: langLoader.get('en'),
    },
    {
      name: "zh",
      value: langLoader.get('zh'),
    },
  ];

  let o={}; // temp
  const languageSelect = ( o = {}, [o.visible, o.setVisible] = useState(false), o);

  const changeLanguage = value => {
    fetch(`${config.api_prefix}/language/me/${value}`, { method: 'POST', credentials: 'include' })
      .then( () => {
        // setLanguage //  useless
        // setUserToken(null); // AuthContext provider
        window.location.reload(false);
      });
  };

  const selectedLanguage = appLanguageSelectOption.find( item => ( item.name == languages.get[0] ) );

  return (
    <View style={[styles.view, { flexDirection: 'row', justifyContent: 'space-between' }]}>
      <SelectMenu
        visible={languageSelect}
        data={appLanguageSelectOption}
        callback={changeLanguage}
        selectedIndex={ ( selectedLanguage ? selectedLanguage : appLanguageSelectOption[0] ).value }
      />
      <Text style={styles.Text}>{title}</Text>
      <SelectView
        value={ ( selectedLanguage ? selectedLanguage : appLanguageSelectOption[0] ).value }
        onPress={() => languageSelect.setVisible(true)}
        selecting={languageSelect.visible}
        style={{minWidth: '50%' }}
      />
    </View>
  );
};

export default LanguageScreen;
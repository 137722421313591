import React, { useEffect, useState } from 'react';
import { StyleSheet, View, FlatList, TouchableOpacity, Text, TextInput } from 'react-native';
import LanguageResourceLoader from '../../language';

import config from '../../config';
import styles from '../../styles';
import { LanguageContext } from '../../context';

import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import MIcon from 'react-native-vector-icons/MaterialIcons';

import ConfirmationBox from '../../components/ConfirmationBox';

import LoadingScreen from '../loading';

const langLoader = new LanguageResourceLoader('biotest.dashboard', ['en', 'zh']);
const stageText = [
  , // no stage 0
  "Selecting user", // 1
  "Filling Form", // 2
  "Preview", // 3
  "Completed", // 4
];

const BiotestsScreen = ({ navigation, route }) => {
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  const [isLoading, setLoading] = useState(true);

  const [biotests, setBotests] = useState([]);

  const [keyword, setKeyword] = useState('');
  const [loadingMore, setLoadingMore] = useState(true);
  const [nextPage, setNextPage] = useState(0);

  let o={}; // temp
  const deleteConfirm = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const deleteSuccess = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const [selectedBiotest, setSelectedBiotest] = useState('');

  const getBiotests = ( keyword = '', append = true, pageIndex = 0, pageLength = 3 ) => {
    fetch(`${config.api_prefix}/biotest?pageLength=${pageLength}&pageIndex=${pageIndex}&sortBy=updated.At&orderBy=desc` + ( keyword ? `&keyword=${keyword}`: '' ), { credentials: 'include' })
      .then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          setLoadingMore( ( pageIndex * pageLength + json.data.length ) < json.total );

          setBotests([
            ...( append ? biotests : [] ),
            ...json.data,
          ]);
          setNextPage( pageIndex + 1 );
          setLoading(false);
        }
        else {
          // Show message
          console.log('invalid userId');
        }
      })
      .catch((error) => console.error(error));
  };

  const deleteBiotest = id => {
    fetch(`${config.api_prefix}/biotest/${id}`, {
      method: 'DELETE',
      credentials: 'include',
    }).then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          deleteSuccess.setVisible(true);
        }
        else {
          // Show message
          console.log('invalid userId');
        }
      })
      .catch((error) => console.error(error));
  };

  const search = keyword => {
    setKeyword(keyword);
    getBiotests(keyword, false);
  };

  const refresh = () => getBiotests(keyword, false);

  useEffect( () => {
    getBiotests('', false);
    // Runs ONCE after initial rendering
    // and after every rendering ONLY IF `prop` or `state` changes
  }, []);

  const biotestLayout = ({ item }) => (
    <View style={[userStyles.card, userStyles.cardUser]}>
      <View style={userStyles.head}>
        <Text style={[
          userStyles.headText,
          userStyles.headTextUser
        ]} >{item._id}</Text>
      </View>
      <Text style={styles.header}>{item.fitnessclient.email ? `${item.fitnessclient.name.surname} ${item.fitnessclient.name.givename}` : `Fitness Client-${item.fitnessclient._id.slice(-8)}`}</Text>
      <View>
        <Text style={{ fontSize: 16 }}>Mobile: {item.fitnessclient.email ? item.fitnessclient.phoneNumber : `??XX%#!??`}</Text>
        <Text style={{ fontSize: 16 }}>Company: {item.owner.company}</Text>
        <Text>>{stageText[item.stage]}</Text>
      </View>
      <View style={userStyles.bottom}>
        <View style={{justifyContent: 'flex-end'}}>
          <Text style={userStyles.date}>Test Date: {(new Date(item.created.At)).toLocaleString('zh', { timeZone: 'Asia/Hong_Kong' })}</Text>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', height: 42 }}>
          <TouchableOpacity>
            <MCIcon
              name={ item.stage < 3 ? "file-document-edit-outline" : "file-check-outline" }
              size={30}
              color="#2196F3"
              style={styles.iconButton}
              onPress={ () => navigation.navigate('BiotestStack', {
                screen: 'BiotestPreview',
                params: { biotestId: item._id }
              })}
            />
          </TouchableOpacity>
          {
            item.stage == 4 ? (
            <TouchableOpacity>
              <MCIcon
                name="ballot-outline"
                size={30}
                color="#2196F3"
                style={styles.iconButton}
                onPress={ () => navigation.navigate('BiotestStack', {
                  screen: 'BiotestReport',
                  params: { biotestId: item._id }
                })}
              />
            </TouchableOpacity>
            ) : (
            <TouchableOpacity>
              <MCIcon
                name="delete"
                size={30}
                color="#2196F3"
                style={styles.iconButton}
                onPress={ () => {
                  setSelectedBiotest({ id: item._id, name: `${item.fitnessclient.name.surname} ${item.fitnessclient.name.givename} (${item.fitnessclient.phoneNumber})`});
                  deleteConfirm.setVisible(true);
                }}
              />
            </TouchableOpacity>
            )
          }
        </View>
      </View>
    </View>
  );

  return isLoading ? <LoadingScreen /> : (
    <View style={styles.view}>
      <ConfirmationBox
        visible={deleteConfirm}
        title={`Is delete Biotest (${selectedBiotest.name})?`}
        data={[
          {value: "Yes", color: "#219700"},
          {value: "No", color: "#AAA"},
        ]}
        callback={ value => { (value == 0) ? deleteBiotest(selectedBiotest.id): 0 }}
      />
      <ConfirmationBox
        visible={deleteSuccess}
        title={`Delete Biotest (${selectedBiotest.name}) Success`}
        data={[
          {value: "Comfirm", color: "#219700"},
        ]}
        callback={refresh}
        defaultIndex={0}
      />
      <View style= {{ flexDirection: 'row', justifyContent: 'space-between', width: 350, padding: 15 }}>
      {/*
        <TouchableOpacity>
          <MIcon
            name="add-chart"
            size={30}
            color="#2196F3"
            style={styles.iconButton}
            onPress={ () => navigation.navigate('BiotestStack', {
              screen: 'CreateBiotest',
              params: { callback: refresh }
            }) }
          />
        </TouchableOpacity>
      */}
        <View style= {{ flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
          <TextInput
            style={{ borderWidth: 1, borderRadius: 5, margin: 5, padding: 5, width: '100%' }}
            autoComplete="on"
            onChange={ e => search(e.target.value) }
            onKeyPress={ e => (e.key === 'Enter') ? search(keyword): false }
          />
          <TouchableOpacity>
            <MIcon
              name="search"
              size={30}
              color="#2196F3"
              style={styles.iconButton}
              onPress={ () => search(keyword) }
            />
          </TouchableOpacity>
        </View>
      </View>
      <FlatList
        ListFooterComponent={
          <View>
            {loadingMore &&
              <Text>Loading More...</Text>
            }
          </View>
        }
        scrollEventThrottle={250}
        onEndReached={info => {
          console.log(info);
          loadingMore ? getBiotests(keyword, true, nextPage): console.log(nextPage);
        }}
        onEndReachedThreshold={0.01}
        data={biotests}
        renderItem={biotestLayout}
        keyExtractor={biotest => biotest._id}
        style={{width: 350, padding: 15 }}
      />
    </View>
  );

};

const userStyles = StyleSheet.create({
  card: {
    borderWidth: 2,
    borderRadius: 10,
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    minHeight: 125,
  },
  cardAdmin: {
    borderColor: 'rgba(255, 102, 102, 0.8)',
    backgroundColor: 'rgba(255, 221, 221, 0.4)',
  },
  cardUser:{
    borderColor: 'rgba(101, 156, 210, 0.8)',
    backgroundColor: 'rgba(219, 237, 255, 0.4)',
  },
  head: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 12,
    fontWeight: 'bold',
  },
  headText: {
    fontWeight: 'bold',
    paddingLeft: 3,
    paddingRight: 3,
  },
  headTextAdmin: {
    backgroundColor: 'rgba(255, 102, 102, 0.8)',
  },
  headTextUser: {
    backgroundColor: 'rgba(101, 156, 210, 0.8)',
  },
  bottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 5,
  },
  date: {
    fontWeight: 'bold',
    fontSize: 11,
    color: '#AAA',
  }
});

export default BiotestsScreen;
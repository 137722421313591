const config = {
  initialRouteName: 'Home',
  screens: {
    // Chat: 'feed/:sort',
    Home: '',
    CreateBiotestStack: {
      initialRouteName: 'CreateBiotest',
      screens: {
        CreateBiotest: {
          path: 'biotest/new',
          stringify: {
            biotestId: o => '',
            fitnessClientId: o => '',
            language: o => '',
            answers: o => '',
          },
        },
        // mirror: not use
        BiotestForm: {
          path: 'biotest/new/:biotestId/form',
          stringify: {
            fitnessClientId: o => '',
            language: o => '',
            answers: o => '',
          },
        },
        // mirror: not use
        BiotestPreview: {
          path: 'biotest/new/:biotestId/preview',
          stringify: {
            fitnessClientId: o => '',
            language: o => '',
            answers: o => '',
          },
        },
        // mirror: not use
        BiotestReport: {
          path: 'biotest/new/:biotestId/report',
          stringify: {
            report: o => '',
          },
        },

        FitnessClientStack: {
          // initialRouteName: 'FitnessClientProfile',
          screens: {
            FitnessClientProfile: 'fitness_client/:fitnessClientId',
            CreateFitnessClient: 'fitness_client/add',
            EditFitnessClient: 'fitness_client/:fitnessClientId/edit' ,
          },
        },
      },
    },
    BiotestHistoryStack: {
      initialRouteName: 'BiotestDashboard',
      screens: {
        BiotestDashboard: 'biotests',
        BiotestStack: {
          // initialRouteName: 'BiotestPreview',
          screens: {
            // Edit First Page
            CreateBiotest: {
              path: 'biotest/:biotestId',
              stringify: {
                fitnessClientId: o => '',
                language: o => '',
                answers: o => '',
              },
            },
            BiotestForm: {
              path: 'biotest/:biotestId/form',
              stringify: {
                fitnessClientId: o => '',
                language: o => '',
                answers: o => '',
              },
            },
            BiotestPreview: {
              path: 'biotest/:biotestId/preview',
              stringify: {
                fitnessClientId: o => '',
                language: o => '',
                answers: o => '',
              },
            },
            BiotestReport: {
              path: 'biotest/:biotestId/report',
              stringify: {
                report: o => '',
              },
            },
            // mirror: not use
            FitnessClientStack: {
              // initialRouteName: 'FitnessClientProfile',
              screens: {
                FitnessClientProfile: 'fitness_client/m/:fitnessClientId',
                CreateFitnessClient: 'fitness_client/m/add',
                EditFitnessClient: 'fitness_client/m/:fitnessClientId/edit',
              },
            },
            // mirror-end: not use
          },
        },
      },
    },
    ProfileStack: {
      initialRouteName: 'Profile',
      screens: {
        Profile:  'user/:userId',
        EditUser: 'user/:userId/edit',
        ChangePassword: 'user/:userId/change_password',
        ReportSetting: 'user/:userId/report_setting',
      },
    },
    UserManagementStack: {
      initialRouteName: 'UserDashboard',
      screens: {
        UserDashboard: 'users',
        CreateUser: {
          path: 'users/new',
          stringify: {
            refresh: o => '',
          },
        },

        UsersProfileStack: {
          initialRouteName: 'Profile',
          screens: {
            Profile: 'users/:userId',
            // not use: redirect to profile
            EditUser: {
              path: 'users/:userId/edit',
              stringify: {
                user: o => '',
              },
              parse: {
                userId: userId => ({ userId, deepLink: true })
              },
            },
            // not use: redirect to profile
            ChangePassword: {
              path: 'users/:userId/change_password',
              parse: {
                userId: userId => ({ userId, deepLink: true })
              },
            },
            // not use: redirect to profile
            ReportSetting: {
              path: 'users/:userId/report_setting',
              parse: {
                userId: userId => ({ userId, deepLink: true })
              },
            },
          },
        },
      },
    },
  },
};

const linking = {
  prefixes: ['sportslab://', 'https://biotestplatform.sportslab.com.hk'],
  config,
};

export default linking;

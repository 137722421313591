import { View, Text } from 'react-native';

import styles from '../../styles';

const ReportSettingScreen = props => {
  return (
    <View style={styles.view}>
      <Text>ReportSetting</Text>
    </View>
  );
};

export default ReportSettingScreen;

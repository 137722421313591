import React, { useEffect, useState } from 'react';
import { View, Pressable, Text, TextInput } from 'react-native';
import LanguageResourceLoader from '../../language';

import config from '../../config';
import styles from '../../styles';
import { IsAdminContext, LanguageContext, ScreensContext } from '../../context';

import ConfirmationBox from '../../components/ConfirmationBox';
import SelectMenu from '../../components/SelectMenu';
import SelectView from '../../components/SelectView';

const langLoader = new LanguageResourceLoader('user', ['en', 'zh']);

const EditProfileScreen = ({ route, navigation }) => {
  const isAdmin = React.useContext(IsAdminContext).isAdmin;
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  // default user data
  const [user, setUser]  = useState( route.params.user ); 

  // Editable data
  const [roleSelectOption, setRoleSelectOption] = useState([
    { id: "default", value: "Select role" },
  ]);
  const [role, setRole] = useState(0);

  const [phoneNumber, setPhoneNumber] = useState( user.phoneNumber ? user.phoneNumber : '' );
  const [surname, setSurname] = useState( user.name ? user.name.surname : '' );
  const [givename, setGivename] = useState( user.name ? user.name.givename : '' );
  const [company, setCompany] = useState( user.company ? user.company : '' );

  const genderSelectOption = [
    { value: langLoader.get('genderDefaultSelect') },
    { value: "M" },
    { value: "F" },
  ];
  const [gender, setGender] = useState( user.gender ? genderSelectOption.findIndex( element => ( element.value == user.gender ) ) : 0 );

  const [message, setMessage]= useState(null);

  let o={}; // temp
  const confirm = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const roleSelect = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const genderSelect = ( o = {}, [o.visible, o.setVisible] = useState(false), o);

  const userProfileScreenContext = React.useContext(ScreensContext).user?.updateUser;

  const getRoles = () => {
    fetch(`${config.api_prefix}/role`, { credentials: 'include' })
      .then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          let loadedRoles = [], selectedRole = 0;

          json.data.forEach( (role, index) => {
            loadedRoles.push({
              id: role._id,
              value: role.name,
            });
            if(role._id == user.roleId)
              selectedRole = loadedRoles.length;
          });

          setRoleSelectOption([
            ...roleSelectOption,
            ...loadedRoles,
          ]);
          setRole(selectedRole);

        }
        else {
          // Show message
          console.log('invalid role Id');
        }
      })
      .catch((error) => console.error(error));
  };

  const editProfile = () => {
    let name = user.name ? user.name : {};
    const data = {
      ...(role && roleSelectOption[role].id != user.roleId && { roleId: roleSelectOption[role].id }),
      ...(phoneNumber && phoneNumber != user.phoneNumber && { phoneNumber }),
      ...(surname && givename && (surname != name.surname || givename != name.givename) && {
        name: {
          surname,
          givename,
        },
      }),
      ...(gender && genderSelectOption[gender].value != user.gender && { gender: genderSelectOption[gender].value }),
      ...(company && company != user.company && { company }),
    };

    if( (surname == '' || givename == '') && !(surname == '' && givename == '')) // surname xor givename
      return setMessage(langLoader.get('messageNeedNames'));

    Object.keys(data).length != 0 ? fetch(`${config.api_prefix}/user/${user._id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(data),
    }).then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          if (typeof userProfileScreenContext?.updateUser == 'function')  userProfileScreenContext.updateUser(json.data);
          confirm.setVisible(true);

          setUser(json.data);
          // setMessage(json.message);
        }
        else {
          // Show message
          setMessage(`Error ${json.code}: ${json.message}`);
        }
      })
      .catch((error) => console.error(error)) : navigation.goBack();
  };

  const submitForm = editProfile;

  useEffect( () => {
    getRoles();
  }, []);

  return (
    <View style={styles.view}>
      <ConfirmationBox
        visible={confirm}
        title={langLoader.get('editUserConfirmMessage')}
        data={[
          {value: langLoader.get('confirmButton'), color: "#219700"},
        ]}
        callback={ () => navigation.goBack() }
      />
      <SelectMenu
        visible={roleSelect}
        data={roleSelectOption}
        callback={setRole}
        selectedIndex={role}
      />
      <SelectMenu
        visible={genderSelect}
        data={genderSelectOption}
        callback={setGender}
        selectedIndex={gender}
      />
      <View style={styles.viewInner}>
        {
          isAdmin ? ( <>
        <Text>{langLoader.get('role')}</Text>
        <SelectView
          value={roleSelectOption[role].value}
          onPress={() => roleSelect.setVisible(true)}
          selecting={roleSelect.visible}
        />
          </> ) : false
        }
        <Text>{langLoader.get('surname')}</Text>
        <TextInput
          value={surname}
          style={styles.input}
          autoComplete="name-family"
          onChange={ e => setSurname(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('givename')}</Text>
        <TextInput
          value={givename}
          style={styles.input}
          autoComplete="name-given"
          onChange={ e => setGivename(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        {
          isAdmin ? ( <>
        <Text>{langLoader.get('company')}</Text>
        <TextInput
          value={company}
          style={styles.input}
          autoComplete="company"
          onChange={ e => setCompany(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
          </> ) : false
        }
        <Text>{langLoader.get('mobile')}</Text>
        <TextInput
          value={phoneNumber}
          style={styles.input}
          autoComplete="tel"
          onChange={ e => setPhoneNumber(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('gender')}</Text>
        <SelectView
          value={genderSelectOption[gender].value}
          onPress={() => genderSelect.setVisible(true)}
          selecting={genderSelect.visible}
        />

        <Text style={styles.warningMessage} >{message}</Text>
        <Pressable
          style={({ pressed }) => [styles.button, { opacity: pressed ? 0.2 : 1 }]}
          onPress={submitForm}
        >
          <Text style={styles.buttonText}>{langLoader.get('saveButton') ? langLoader.get('saveButton').toUpperCase() : ''}</Text>
        </Pressable>
      </View>
    </View>
  );

};

export default EditProfileScreen;

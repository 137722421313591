import React, { useEffect, useState } from 'react';
import { Dimensions, StyleSheet, ScrollView, View, Modal, FlatList, Pressable, Text } from 'react-native';

const ConfirmationBox = ({
  visible: {
    visible,
    setVisible
  },
  callback,
  title,
  data = [
    {value: "Confirm", color: "#219700"},
    {value: "Cancel", color: "#AAA"},
  ],
  defaultIndex = null,
}) => {
  const [screenHeight, setHeight] = useState(Dimensions.get('window').height);
  const [screenWidth, setWidth] = useState(Dimensions.get('window').width);
  let dimensionsSubscription;

  const back = (e, value) => {
    ( (typeof callback) === "function" && value !== null ) ? callback(value): 0;
    setVisible(false);
  }

  useEffect( () => {
    if(visible)
      dimensionsSubscription = Dimensions.addEventListener("change", ({ window }) => {
        setHeight(window.height);
        setWidth(window.width);
      });
    else
      dimensionsSubscription?.remove();
  }, [visible]);

  const buttonLayout = ({ item, index }) => (
    <Pressable
      style={({ pressed }) => [styles.button, { backgroundColor: item.color ? item.color : "#219700", opacity: pressed ? 0.2 : 1 }]}
      onPress={e => back(e, item.name ? item.name : index)}
    >
      <Text style={styles.buttonText}>{ item.value ? item.value : item.name }</Text>
    </Pressable>
  );

  return (
    <Modal
      animationType="none"
      transparent={true}
      visible={visible}
      onRequestClose={back}
    >
    <ScrollView>
      <Pressable
        style={[styles.background, { minHeight: screenHeight }]}
        onPress={event => ( event.target == event.currentTarget ? back(event, defaultIndex) : 0 )}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>{title}</Text>
            <FlatList
              data={data}
              renderItem={buttonLayout}
              keyExtractor={button => (button.name ? button.name : button.value)}
              contentContainerStyle={{ justifyContent: "center", flexDirection: 'row' }}
            />
          </View>
        </View>
      </Pressable>
    </ScrollView>
    </Modal>
  )
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.62)',
    cursor: 'auto',

    padding: 40,
  },
  // center the modal on screen
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
  },
  // modal style
  modalView: {
    backgroundColor: "rgba(242,242,242,0.85)",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 0
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  // title
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    fontWeight: "bold",

  },

  // simluate default button
  button: {
    borderRadius: 3,
    padding: 10,
    // elevation: 2,
    margin: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 0
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },

});

export default ConfirmationBox;
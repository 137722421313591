import { StyleSheet, View, Modal, FlatList, Text } from 'react-native';

const LinearLevelSelect = ({
  label = ["1", "5"],
  data = [
    "1",
    "2",
    "3",
    "4",
    "5",
  ],
  defaultIndex = null,
}) => {
  const previewLayout = ({ item, index }) => (
    <Text>{defaultIndex == index ? '⦿':'◯'}</Text>
  )

  return (
    <View style={styles.linearLevelSelectItem}>
      <View style={styles.row}>
        <Text>{label[0]}</Text>
        <Text>{label[1]}</Text>
      </View>
      <FlatList
        data={data}
        renderItem={previewLayout}
        keyExtractor={ value => value }
        contentContainerStyle={styles.row}
        horizontal={true}
      />
    </View>
  )
};

const styles = StyleSheet.create({
  linearLevelSelectItem: {
    marginLeft: 12,
    marginRight: 12,
    elevation: 2,

    width:'initial',
    minWidth: 100,
    minHeight: 40,
  },
  row: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    margin: 10,
  },
});

export default LinearLevelSelect;




import React, { useEffect, useState } from 'react';
import { View, Pressable, Text } from 'react-native';
import LanguageResourceLoader from '../../language';

import config from '../../config';
import styles from '../../styles';
import { LanguageContext } from '../../context';

import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';

import LoadingScreen from '../loading';

import { toBase64 } from '../../base64kit';
import { loadData } from './loadData';

const langLoader = new LanguageResourceLoader('biotest', ['en', 'zh']);

let biotestId = null, objectURL = '', fileLoading = false;

const BiotestReportScreen = ({ navigation, route }) => {
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  const isFocused = navigation.isFocused();
  const [isLoading, setLoading] = useState(true);

  const [report, setReport] = useState({});
  const [reportLanguage, setReportLanguage] = useState({});
  const [filename, setFilename] = useState('');

  const callback = json => {
    if(json.code == 200){
      if(json.data.stage != 4) navigation.goBack();

      setReport(json.data.report);
      setReportLanguage(json.data.language);
      setLoading(false);
    }
    else {
      console.log(`Biotest Loading Error ${json.code}: ${json.message}`);
    }
  };

  useEffect( () => {
    if( isFocused == false ) return URL.revokeObjectURL(objectURL); // leave page => nothing to do
    biotestId = route.params.biotestId;

    const path = route.path;
    if( path && path.startsWith("/biotest/new/") ) return navigation.goBack();
    if(!route.params.report) return loadData(route.params.biotestId, callback);

    setReport(route.params.report ? route.params.report : {})
    // need refresh every load
  }, [isFocused, route.params.biotestId]);

  return isLoading ? <LoadingScreen /> : (
    <View style={styles.view}>
    { /*getOS() == "Windows"*/false ? (
      // Display Preview
      <></>
    ) : (
      <>
      <MCIcon
        name="file-pdf-box"
        size={50}
        color="#DDDDDD"
      />
      <Text>{filename}</Text>
      <Pressable
        style={({ pressed }) => [styles.button, { minWidth: 230, opacity: pressed ? 0.2 : 1 }]}
        onPress={ () => {
          const openFile = objectURL => {
            setFilename('Downloading');
            window.open(objectURL, '_blank');
            setFilename( `${biotestId}.pdf` );
            fileLoading = false;
          };

          if(fileLoading) return console.log('Loading');

          fileLoading = true;
          setFilename('Loading');
          (filename == `${biotestId}.pdf`) ? openFile(objectURL): fetch(`${config.pdf_generator}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            data: toBase64(JSON.stringify({...report, language: reportLanguage})),
          }),
        })
        .then(response => response.blob())
        .then(function (myBlob) {
          const file = new File([myBlob], `${biotestId}.pdf`, { 'type': 'application/pdf' });
          objectURL = URL.createObjectURL(file);
          openFile(objectURL);
        })
        .catch((error) => { fileLoading = false; setFilename('Error'); } );
      }}
      >
        <Text style={styles.buttonText}>{langLoader.get('downloadButton')}</Text>
      </Pressable>
      </>
    ) }
    </View>
  );

};

function getOS() {
  var userAgent = window.navigator.userAgent,
      platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

export default BiotestReportScreen;
import config from '../../config';

const loadData = (biotestId, callback = json => {}, errorCallback = e => console.log(e)) => {
  fetch(`${config.api_prefix}/biotest/${biotestId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })
  .then( response => response.json() )
  .then( callback )
  .catch( errorCallback );
};

export { loadData };
import React, { useEffect, useState } from 'react';
import { StyleSheet, SafeAreaView, ScrollView, View, FlatList, Pressable, Text, TextInput } from 'react-native';
import LanguageResourceLoader from '../../language';

import config from '../../config';
import styles from '../../styles';
import { LanguageContext } from '../../context';

import ConfirmationBox from '../../components/ConfirmationBox';
import Switch from '../../components/Switch';
import SelectMenu from '../../components/SelectMenu'
import SelectView from '../../components/SelectView';
import LinearLevelSelect from '../../components/LinearLevelSelect'

import { loadData } from './loadData';

const questionsLoader = new LanguageResourceLoader('question', ['en', 'zh']);
const langLoader = new LanguageResourceLoader('biotest', ['en', 'zh']);

// Dynamic Function
let selectCallback = () => {};
let biotestId = null, fitnessClientId = null, reportLanguage = null;

const BiotestFormScreen = ({ navigation, route }) => {
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);
  questionsLoader.setPriority(languages.get);
  const questions = questionsLoader.get(0).default;

  // Const
  const defaultSelect = langLoader.get('defaultSelect');

  const [answers, setAnswers] = useState({});
  const [check, setCheck] = useState(false);

  const [selectingItem, setSelectingItem] = useState(null); // Record Key for common Select callback
  const [commonSelectOption, setCommonSelectOption] = useState([
    {
      value: defaultSelect
    },
  ]);
  const [selectValue, setSelectValue] = useState();

  let o={}; // temp
  const commonSelect = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const warningConfirm = ( o = {}, [o.visible, o.setVisible] = useState(false), o);

  const updateAnswer = (questionIds/* Array or String*/, answer = null, sync = true) => {
    const subQuestion = questionIds.subQuestion,
      questionId = (typeof questionIds == 'object') ? questionIds.questionId : questionIds;
    let newAnswers = answers;

    let orgAnswer = answers[questionId];
    if(subQuestion != null && orgAnswer != null) orgAnswer = orgAnswer[subQuestion];

    // Answer is a new value
    if(answer != orgAnswer) {
      if(answer != null) {
        // Add/ Update new value
        newAnswers = {
          ...answers,
          [questionId]: subQuestion == null ? answer : {
            ...answers[questionId],
            [subQuestion]: answer,
          },
        };
      }
      else {
        // Delete exisit value
        if(subQuestion == null)
          delete answers[questionId];
        else {
          delete answers[questionId][subQuestion];
          if(Object.keys(answers[questionId]).length == 0) delete answers[questionId];
        }

        newAnswers = {};
        Object.keys(answers).forEach(key => {
          newAnswers[key] = answers[key];
        });
      }

      setAnswers(newAnswers);
      sync ? updateServer(newAnswers) : false;
    }
  }

  // need update
  const updateServer = (newAnswers = answers) => {
    fetch(`${config.api_prefix}/biotest/${biotestId}/syncaAnswers`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ answers: newAnswers }),
    }).then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          // saved
        }
        else {
          // Show message
          console.log(`Error ${json.code}: ${json.message}`);
        }
      })
      .catch((error) => console.error(error));
  }

  const setSelectData = (question/* Object*/, type, subQuestion = null) => {
    let key = '', answer = '', loadOptions, options = [
      {
        value: defaultSelect
      }
    ];

    switch (type) {
      case 2: // select
      default:
        key = question.id;
        answer = answers[question.id];
        loadOptions = question.options;
        break;

      case 3: // sub-select
        key = `${question.id}.${subQuestion}`;
        answer = answers[question.id] == null ? 0 : answers[question.id][subQuestion];
        loadOptions = question.commonOptions;
        break;

      case 9: // special-sub-select
        key = `${question.id}.${subQuestion}`;
        answer = answers[question.id] == null ? 0 : answers[question.id][subQuestion];
        loadOptions =  question.commonOptions[ Array.isArray(question.subQuestions[subQuestion]) ? question.subQuestions[subQuestion][1] : 0 ];
        break;
    }

    for( let i=0; i<loadOptions.length; i++)
      options.push({
        value: loadOptions[i],
      });

    setSelectingItem(key);
    setSelectValue(answer == null ? 0 : answer);
    setCommonSelectOption(options);

    selectCallback = value => updateAnswer({ questionId: question.id, subQuestion }, value == 0 ? null : value)
  }

  // Preview
  const stage2check = () => {
    let ok = true;
    setCheck(true);

    questions.forEach(question => {
      ok = ok && answers[question.id] != null;

      if((question.type==3 || question.type==8) && answers[question.id] != null) ok = ok && question.subQuestions.length == Object.keys(answers[question.id]).length
    })

    if(!ok) return warningConfirm.setVisible(true);

    navigation.navigate('BiotestPreview', { biotestId, fitnessClientId, language: reportLanguage, answers });
  }

  useEffect( () => {
    biotestId = route.params.biotestId;

    const path = route.path;
    if( path && path.startsWith("/biotest/new/") ) return navigation.goBack();
    if(!route.params.fitnessClientId || !route.params.language)
      return loadData(biotestId, json => {
        if(json.code == 200 && Number(json.data.stage) <= 3) {
          fitnessClientId = json.data.fitnessClientId;
          reportLanguage = json.data.language;
          setAnswers(json.data.answers ? json.data.answers : {});
        }
        else {
          console.log(`Biotest Loading Error ${json.code}: ${json.message}`);
          navigation.goBack();
        }
      });

    fitnessClientId = route.params.fitnessClientId;
    reportLanguage = route.params.language;
    setAnswers(route.params.answers ? route.params.answers : {});
    // need refresh every load
  }, [route.params]);

  const TitleLayout = ({ title }) => ( title ?
    <View style={styles.titleShell}>
      <Text style={styles.title}>{langLoader.get(title)}</Text>
    </View>
  :
    <></>
  )

  const questionLayouts = {
    openInput: ({ item, questionNumber }) => (
      <>
        <TitleLayout title={item.title} />
        <Text style={[biotestFormStyles.question, check && !answers[item.id] ? biotestFormStyles.alert : {} ]}>Q{questionNumber}. {item.question}</Text>
        <TextInput
          style={styles.input}
          placeholder={item.placeholder ? item.placeholder : ''}
          onChange={ e => (/^([0-9.]{0,})$/.test(e.target.value) ? updateAnswer(item.id, e.target.value == '' ? null : e.target.value, false) : "illegal input" ) }
          onBlur={ e => updateServer() }
          value={answers[item.id] ? answers[item.id] : ""}
        />
      </>
    ),
    'sub-openInput': ({ item, questionNumber }) => {
      const questionObj = item;
      let subQuestionCheck = answers[item.id] != null;

      if(subQuestionCheck) subQuestionCheck = item.subQuestions.length == Object.keys(answers[item.id]).length;

      return (
        <>
          <TitleLayout title={questionObj.title} />
          <Text style={[biotestFormStyles.question, check && !subQuestionCheck ? biotestFormStyles.alert : {} ]}>Q{questionNumber}. {questionObj.question}</Text>
          <FlatList
            data={questionObj.subQuestions}
            renderItem={ ({ item, index }) => {
              const subAnswers = answers[questionObj.id] ? answers[questionObj.id] : {};
              const subAnswer = subAnswers[index];

              return (
                <>
                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ justifyContent: 'center', marginLeft: 5, marginRight: 5, maxWidth: 170 }}>
                      <Text style={(check && subAnswer == null ? biotestFormStyles.alert : {})}>{item}</Text>
                    </View>
                    <TextInput
                      style={styles.input}
                      placeholder={questionObj.placeholder ? questionObj.placeholder : ''}
                      onChange={ e => (/^([0-9.]{0,})$/.test(e.target.value) ? updateAnswer({ questionId: questionObj.id, subQuestion: index }, e.target.value == '' ? null : e.target.value, false) : "illegal input" ) }
                      onBlur={ e => updateServer() }
                      value={subAnswer ? subAnswer : ""}
                    />
                  </View>
                </>
              )
            }}
            keyExtractor={item => item}
          />
        </>
      )
    },
    switch: ({ item, questionNumber }) => (
      <>
        <TitleLayout title={item.title} />
        <Text style={[biotestFormStyles.question, check && answers[item.id] == null ? biotestFormStyles.alert : {} ]}>Q{questionNumber}. {item.question}</Text>
        <Switch
          data={item.option}
          callback={ value => updateAnswer(item.id, value) }
          defaultIndex={ answers[item.id] == null ? null : answers[item.id] }
        />
      </>
    ),
    select: ({ item, questionNumber }) => (
      <>
        <TitleLayout title={item.title} />
        <Text style={[biotestFormStyles.question, check && !answers[item.id] ? biotestFormStyles.alert : {} ]}>Q{questionNumber}. {item.question}</Text>
        <SelectView
          style={{ minWidth: 100 }}
          value={ answers[item.id] == null ? defaultSelect : item.options[answers[item.id] - 1] }
          onPress={() => {
            setSelectData(item, item.type, null);
            commonSelect.setVisible(true);
          }}
          selecting={commonSelect.visible && selectingItem == item.id }
        />
      </>
    ),
    'sub-select': ({ item, questionNumber }) => {
      const questionObj = item;
      let subQuestionCheck = answers[item.id] != null;

      if(subQuestionCheck) subQuestionCheck = item.subQuestions.length == Object.keys(answers[item.id]).length;

      return (
        <>
          <TitleLayout title={questionObj.title} />
          <Text style={[biotestFormStyles.question, check && !subQuestionCheck ? biotestFormStyles.alert : {} ]}>Q{questionNumber}. {questionObj.question}</Text>
          <FlatList
            data={questionObj.subQuestions}
            renderItem={ ({ item, index }) => {
              const subAnswers = answers[questionObj.id] ? answers[questionObj.id] : {};
              const subAnswer = subAnswers[index];

              return (
                <>
                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ justifyContent: 'center', marginLeft: 5, marginRight: 5, maxWidth: 170 }}>
                      <Text style={(check && subAnswer == null ? biotestFormStyles.alert : {})}>{item}</Text>
                    </View>
                    <SelectView
                      style={{ minWidth: 100 }}
                      value={ subAnswer == null ? defaultSelect : questionObj.commonOptions[subAnswer - 1] }
                      onPress={() => {
                        setSelectData(questionObj, 3, index);
                        commonSelect.setVisible(true)
                      }}
                      selecting={commonSelect.visible && selectingItem == `${questionObj.id}.${index}`}
                    />
                  </View>
                </>
              )
            }}
            keyExtractor={item => item}
          />
        </>
      )
    },
    'special-sub-select': ({ item, questionNumber }) => {
      const questionObj = item;
      let subQuestionCheck = answers[item.id] != null;

      if(subQuestionCheck) subQuestionCheck = item.subQuestions.length == Object.keys(answers[item.id]).length;

      return (
        <>
          <TitleLayout title={questionObj.title} />
          <Text style={[biotestFormStyles.question, check && !subQuestionCheck ? biotestFormStyles.alert : {} ]}>Q{questionNumber}. {questionObj.question}</Text>
          <FlatList
            data={questionObj.subQuestions}
            renderItem={ ({ item, index }) => {
              const subAnswers = answers[questionObj.id] ? answers[questionObj.id] : {};
              const subAnswer = subAnswers[index];

              const subQuestion = Array.isArray(item) ? item[0] : item;
              const commonOptions = questionObj.commonOptions[ Array.isArray(item) ? item[1] : 0 ];

              return (
                <>
                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ justifyContent: 'center', marginLeft: 5, marginRight: 5, maxWidth: 170 }}>
                      <Text style={(check && subAnswer == null ? biotestFormStyles.alert : {})}>{subQuestion}</Text>
                    </View>
                    <SelectView
                      style={{ minWidth: 100 }}
                      value={ subAnswer == null ? defaultSelect : commonOptions[subAnswer - 1] }
                      onPress={() => {
                        setSelectData(questionObj, 9, index);
                        commonSelect.setVisible(true)
                      }}
                      selecting={commonSelect.visible && selectingItem == `${questionObj.id}.${index}`}
                    />
                  </View>
                </>
              )
            }}
            keyExtractor={(item, index) => (Array.isArray(item) ? index : item ) }
          />
        </>
      )
    },
    linearLevel: ({ item, questionNumber }) => (
      <>
        <TitleLayout title={item.title} />
        <Text style={[biotestFormStyles.question, check && answers[item.id] == null ? biotestFormStyles.alert : {} ]}>Q{questionNumber}. {item.question}</Text>
        <LinearLevelSelect
          label={item.label}
          data={item.represent}
          defaultIndex={ answers[item.id] }
          callback={ value => updateAnswer(item.id, value) }
        />
      </>
    ),
  };

  const questionLayout = ({ item, index }) => {
    const questionNumber = index + 1;
    switch(item.type){
      case 0:
        return questionLayouts.openInput({ item, questionNumber });

      case 1:
        return questionLayouts.switch({ item, questionNumber });

      case 2:
        return questionLayouts.select({ item, questionNumber });

      case 3:
        return questionLayouts['sub-select']({ item, questionNumber });

      case 4:
        return questionLayouts.linearLevel({ item, questionNumber });

      case 8:
        return questionLayouts['sub-openInput']({ item, questionNumber });

      case 9:
        return questionLayouts['special-sub-select']({ item, questionNumber });

      default:
        return (<></>);
    }
  };

  return (
    <View style={styles.view}>
      <SelectMenu
        visible={commonSelect}
        data={commonSelectOption}
        callback={selectCallback}
        selectedIndex={selectValue}
      />
      <ConfirmationBox
        visible={warningConfirm}
        title={langLoader.get('answerAllQuestionsMessage')}
        data={[
          {value: "OK", color: "#219700"},
        ]}
      />
      <View style={[styles.viewInner, { paddingTop: 50, paddingBottom: 50 }]}>
        <ScrollView>
          <FlatList
            data={questions}
            renderItem={questionLayout}
            keyExtractor={item => item.id}
          />
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Pressable
              style={({ pressed }) => [styles.button, { opacity: pressed ? 0.2 : 1 }]}
              onPress={ () => navigation.goBack()/* navigation.navigate('CreateBiotest', { biotestId, fitnessClientId, language: reportLanguage, answers }) */ }
            >
              <Text style={styles.buttonText}>{langLoader.get('backButton')}</Text>
            </Pressable>
            <Pressable
              style={({ pressed }) => [styles.button, { opacity: pressed ? 0.2 : 1 }]}
              onPress={stage2check}
            >
              <Text style={styles.buttonText}>{langLoader.get('previewButton')}</Text>
            </Pressable>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const biotestFormStyles = StyleSheet.create({
  question: {
    fontWeight: 'bold',
  },
  alert: {
    color: 'red',
  },
});

export default BiotestFormScreen;

import React, { useState } from 'react';
import { View, Pressable, Text, TextInput } from 'react-native';
import LanguageResourceLoader from '../../language';

import config from '../../config';
import styles from '../../styles';
import { LanguageContext } from '../../context';

import ConfirmationBox from '../../components/ConfirmationBox';

const langLoader = new LanguageResourceLoader('user', ['en', 'zh']);

const ChangePasswordScreen = ({ navigation, route }) => {
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [comfirmPassword, setComfirmPassword] = useState('');
  const [message, setMessage]= useState(null);

  let o={}; // temp
  const confirm = ( o = {}, [o.visible, o.setVisible] = useState(false), o);

  const changePassword = () => {
    if(comfirmPassword != newPassword){
      setMessage(langLoader.get('messageIncorrectPW'));
      return 0;
    }

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        password: route.params.userId == 'me' ? password : "fake password",
        newPassword: newPassword,
      }),
    };

    fetch(`${config.api_prefix}/user/${route.params.userId == 'me' ? '': (route.params.userId + '/')}changePassword`, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          confirm.setVisible(true);
          // setMessage(json.message) //maybe sessionId?
        }
        else {
          // Show message
          setMessage(`Error ${json.code}: ${json.message}`);
        }
      })
      .catch((error) => console.error(error));
      // .finally(() => setLoading(false));
  };

  const submitForm = changePassword;

  return (
    <View style={styles.view}>
      <ConfirmationBox
        visible={confirm}
        title={langLoader.get('changePWConfirmMessage')}
        data={[
          {value: langLoader.get('confirmButton'), color: "#219700"},
        ]}
        callback={ () => navigation.goBack() }
      />
      <View style={styles.viewInner}>
        {
          route.params.userId == 'me' ? ( <>
            <Text>{langLoader.get('password')}</Text>
            <TextInput
              style={styles.input}
              autoComplete="password"
              secureTextEntry={true}
              minLength={6}
              onChange={ e => setPassword(e.target.value) }
              onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
            />
          </> ) : false
        }
        <Text>{langLoader.get('newPassword')}</Text>
        <TextInput
          style={styles.input}
          autoComplete="password-new"
          secureTextEntry={true}
          minLength={6}
          onChange={ e => setNewPassword(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('repeatPassowrd')}</Text>
        <TextInput
          style={styles.input}
          autoComplete="password-new"
          secureTextEntry={true}
          minLength={6}
          onChange={ e => setComfirmPassword(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />

        <Text style={styles.warningMessage} >{message}</Text>
        <Pressable
          style={({ pressed }) => [styles.button, { opacity: pressed ? 0.2 : 1 }]}
          onPress={submitForm}
        >
          <Text style={styles.buttonText}>{langLoader.get('changePWButton') ? langLoader.get('changePWButton').toUpperCase() : ''}</Text>
        </Pressable>
      </View>
    </View>
  );

};

export default ChangePasswordScreen;

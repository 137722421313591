import React, { useEffect, useState } from 'react';
import { Dimensions, StyleSheet, ScrollView, View, Modal, FlatList, Pressable, TouchableOpacity, Text, TextInput } from 'react-native';

import MIcon from 'react-native-vector-icons/MaterialIcons';

const SelectMenu = ({
  visible: {
    visible,
    setVisible
  },
  callback,
  searchCallback = () => {},
  data = [
    {name: "Select", value: "Select"},
  ],
  selectedIndex = 0,
}) => {

  const [screenHeight, setHeight] = useState(Dimensions.get('window').height);
  const [screenWidth, setWidth] = useState(Dimensions.get('window').width);
  let dimensionsSubscription;

  const [keyword, setKeyword] = useState('');

  const back = (e, value) => {
    (typeof callback) === "function" ? callback(value): 0;
    setVisible(false);
  };

  useEffect( () => {
    if(visible)
      dimensionsSubscription = Dimensions.addEventListener("change", ({ window }) => {
        setHeight(window.height);
        setWidth(window.width);
      });
    else {
      dimensionsSubscription?.remove();
      setKeyword('');
    }
  }, [visible]);

  const selectItemLayout = ({ item, index }) => index == 0 ? (
    <View style={[styles.selectItem, { flexDirection: 'row', justifyContent: 'space-between', maxWidth: screenWidth - 35 * 2 /* modalView.padding */ - 40 * 2 /* background.padding */}]}>
        <TextInput
          style={{ borderWidth: 1, borderRadius: 5, marginRight: 5, padding: 5, width: '100%' }}
          autoComplete="on"
          onChange={ e => {
            setKeyword(e.target.value);
            searchCallback(e.target.value);
          } }
          onKeyPress={ e => (e.key === 'Enter') ? searchCallback(keyword): false }
        />
        <TouchableOpacity style={{ marginLeft: 5 }}>
          <MIcon
            name="search"
            size={30}
            color="#219700"
            onPress={ () => searchCallback(keyword) }
          />
        </TouchableOpacity>
    </View>
  ) : (
    <Pressable
      style={[styles.selectItem, { maxWidth: screenWidth - 35 * 2 /* modalView.padding */ - 40 * 2 /* background.padding */}]}
      onPress={e => back(e, item.name ? item.name : index)}
    >
      <Text style={{ marginRight: 10 }}>{ typeof selectedIndex === 'string' && item.name == selectedIndex || selectedIndex == index ? '⦿':'◯' }</Text>
      <Text style={styles.selectItemText}>{ item.value ? item.value : item.name }</Text>
    </Pressable>
  );

  return (
    <Modal
      animationType="none"
      transparent={true}
      visible={visible}
      onRequestClose={back}
    >
    <ScrollView>
      <Pressable
        style={[styles.background, { minHeight: screenHeight }]}
        onPress={event => event.target == event.currentTarget && setVisible(false)}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <FlatList
              data={data}
              renderItem={selectItemLayout}
              keyExtractor={selectItem => (selectItem.name ? selectItem.name : selectItem.value)}
            />
          </View>
        </View>
      </Pressable>
    </ScrollView>
    </Modal>
  )
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.62)',
    cursor: 'auto',

    padding: 40,
  },
  // center the modal on screen
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
  },
  // modal style
  modalView: {
    backgroundColor: "rgba(242,242,242,0.85)",
    borderRadius: 5,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 0
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },

  // simluate default select item
  selectItem: {
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 3,
    padding: 10,
    margin: 3,
    elevation: 2,

    width:'initial',
    minWidth: 300,
    minHeight: 40,
  },
  selectItemText: {
  },

});

export default SelectMenu;
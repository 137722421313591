class LanguageResourceLoader {

  constructor(resource, languages) {
    this.resourceName = resource;
    this.resourceMap = {};
    this.setPriority(languages);
    this.init(resource, languages);
  }

  init(resource, languages) {
    for(let i=0; i<this.priority.length; i++)
      this.loadData(this.priority[i]).catch( e => console.log(`No ${this.priority[i]} language set of ${resource}.\n`, e) );
  }

  setPriority(languages) {
    this.priority = Array.isArray(languages) ? languages : [languages];
  }

  async loadData(language) {
    const data = await import(`./${this.resourceName ? (this.resourceName+".") : "" }${language}.json`);
    this.resourceMap[language] = Array.isArray(data.default) ? { 0: data } : data;
  }

  get(key) {
    for(let i=0; i<this.priority.length; i++){
      if(!this.resourceMap[this.priority[i]]) continue;
      if(typeof this.resourceMap[this.priority[i]][key] != 'undefined')
        return this.resourceMap[this.priority[i]][key];
    }

    return undefined;
  }

  async getFullContent() {
    let data = {};

    for(let i=0; i<this.priority.length; i++){
      if(!this.resourceMap[this.priority[i]]) continue;
      data = { ...this.resourceMap[this.priority[i]], ...data };
    }

    return data;
  }

  status() {
    return this;
  }

};

class LanguageResourceLoaderLazy extends LanguageResourceLoader {

  init(resource, languages) {
    this.loadData(this.priority[0]).catch( e => console.log(`No ${this.priority[i]} language set of ${resource}.\n`, e) );
  }

  async get(key) {
    for(let i=0; i<this.priority.length; i++){
      try {
        if(!this.resourceMap[this.priority[i]]) await this.loadData(this.priority[i]);
        if(typeof this.resourceMap[this.priority[i]][key] != 'undefined')
          return this.resourceMap[this.priority[i]][key];
      } catch {
        continue;
      }
    }

    return undefined;
  }

  async getFullContent() {
    let data = {};

    for(let i=0; i<this.priority.length; i++){
      try {
        if(!this.resourceMap[this.priority[i]]) await this.loadData(this.priority[i]);
        data = { ...this.resourceMap[this.priority[i]], ...data };
      } catch {
        continue;
      }
    }

    return data;
  }

};

export default LanguageResourceLoader;
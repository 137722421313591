import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import LanguageResourceLoader from '../../language';

import config from '../../config';
import styles from '../../styles';
import { LanguageContext, ScreensContext } from '../../context';

import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';

import ConfirmationBox from '../../components/ConfirmationBox';

import LoadingScreen from '../loading';

const langLoader = new LanguageResourceLoader('fitnessclient', ['en', 'zh']);

const FitnessClientProfileScreen = ({ route, navigation }) => {
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  const [isLoading, setLoading] = useState(true);
  const [fitnessClient, setFitnessClient] = useState({});

  let o={}; // temp
  const deleteConfirm = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const deleteSuccess = ( o = {}, [o.visible, o.setVisible] = useState(false), o);

  const biotestCreateScreenContext = React.useContext(ScreensContext).biotest?.create;

  const getFitnessClientDetail = fitnessClientId => {
    fetch(`${config.api_prefix}/fitnessClient/${fitnessClientId}`, { credentials: 'include' })
      .then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          setFitnessClient(json.data);
          setLoading(false);
        }
        else {
          // Show message
          console.log('invalid fitnessClientId');
        }
      })
      .catch((error) => console.error(error));
  };

  const deleteFitnessClient = fitnessClientId => {
    fetch(`${config.api_prefix}/fitnessClient/${fitnessClientId}`, {
      method: 'DELETE',
      credentials: 'include',
    }).then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          deleteSuccess.setVisible(true);
        }
        else {
          // Show message
          console.log('invalid userId');
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect( () => {
    getFitnessClientDetail(route.params.fitnessClientId);
    // Runs ONCE after initial rendering
    // and after every rendering ONLY IF `prop` or `state` changes
  }, [route.params.fitnessClientId]);console.log(langLoader);

  return isLoading ? <LoadingScreen /> : (
    <View style={styles.view}>
      <ConfirmationBox
        visible={deleteConfirm}
        title={langLoader.get('isDeleteFitnessClientMessage') ? `${langLoader.get('isDeleteFitnessClientMessage')[0]}${fitnessClient.name.surname} ${fitnessClient.name.givename}${langLoader.get('isDeleteFitnessClientMessage')[1]}`: ''}
        data={[
          {value: langLoader.get('yesButton'), color: "#219700"},
          {value: langLoader.get('noButton'), color: "#AAA"},
        ]}
        callback={ value => { (value == 0) ? deleteFitnessClient(route.params.fitnessClientId): 0 }}
      />
      <ConfirmationBox
        visible={deleteSuccess}
        title={langLoader.get('deleteFitnessClientrMessage') ? `${langLoader.get('deleteFitnessClientrMessage')[0]}${fitnessClient.name.surname} ${fitnessClient.name.givename}${langLoader.get('deleteFitnessClientrMessage')[1]}`: ''}
        data={[
          {value: langLoader.get('confirmButton'), color: "#219700"},
        ]}
        callback={() => {
          if(typeof biotestCreateScreenContext?.loadAndSetFitnessClientId == 'function')
            biotestCreateScreenContext.loadAndSetFitnessClientId("0");

          navigation.goBack();
        }}
        defaultIndex={0}
      />

      <View style={{ justifyContent: 'flex-end', flexDirection: "row", height: 42 }} >
        <TouchableOpacity>
          <MCIcon
            name="account-edit"
            size={30}
            color="#2196F3"
            style={styles.iconButton}
            onPress={ () => navigation.navigate('EditFitnessClient', { fitnessClient: fitnessClient, fitnessClientId: route.params.fitnessClientId, callback: setFitnessClient }) }
          />
        </TouchableOpacity>
        <TouchableOpacity>
          <MCIcon
            name="delete"
            size={30}
            color="#2196F3"
            style={styles.iconButton}
            onPress={ () => deleteConfirm.setVisible(true) }
          />
        </TouchableOpacity>
      </View>

      <Text style={styles.text}>{langLoader.get('memberNumber')}: {fitnessClient.memberNumber}</Text>
      <Text style={styles.text}>{langLoader.get('email')}: {fitnessClient.email}</Text>
      <Text style={styles.text}>{langLoader.get('mobile')}: { fitnessClient.phoneNumber ? `${fitnessClient.phoneNumber.slice(0,4)} ${fitnessClient.phoneNumber.slice(4,8)}` : "---- ----" }</Text>
      <Text style={styles.text}>{langLoader.get('name')}: { fitnessClient.name ? `${fitnessClient.name.surname} ${fitnessClient.name.givename}` : "----- ----- -----" }</Text>
      <Text style={styles.text}>{langLoader.get('gender')}: { fitnessClient.gender ? fitnessClient.gender : langLoader.get('genderNotSet') }</Text>
    </View>
  );
};

export default FitnessClientProfileScreen;

import React, { useEffect, useState } from 'react';
import { StyleSheet, View, FlatList, Pressable, Text } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import LanguageResourceLoader from '../../language';

import config from '../../config';
import styles from '../../styles';
import { LanguageContext } from '../../context';

import LinearLevelPreview from '../../components/LinearLevelPreview';
import ConfirmationBox from '../../components/ConfirmationBox';

import LoadingScreen from '../loading';

import { loadData } from './loadData';

const questionsLoader = new LanguageResourceLoader('question', ['en', 'zh']);
const langLoader = new LanguageResourceLoader('biotest', ['en', 'zh']);

let biotestId = null, fitnessClientId = null, answers = {}, requestLoading = true;

const BiotestPreviewScreen = ({ navigation, route }) => {
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);
  questionsLoader.setPriority(languages.get);
  const questions = questionsLoader.get(0).default;

  const isFocused = useIsFocused();
  const [isLoading, setLoading] = useState(true);

  const [previewData, setPreviewData] = useState({});
  const reportLanguageSelectOption = [
    {
      name: "en",
      value: langLoader.get('language-en'),
    },
    {
      name: "zh",
      value: langLoader.get('language-zh'),
    },
  ];
  const [reportLanguage, setReportLanguage] = useState("en");
  const [stage, setStage] = useState(null);

  let o={}; // temp
  const generateReportConfirm = ( o = {}, [o.visible, o.setVisible] = useState(false), o);

  const callback = json => {
    if(json.code == 200){

      fitnessClientId = json.data.fitnessClientId;
      answers = json.data.answers ? json.data.answers : {};
      json.data.language ? setReportLanguage(json.data.language) : false;
      setStage(Number(json.data.stage));

      (json.data.stage >= 3) ? setPreviewData(json.data.preview) : generatePreview(fitnessClientId, answers, false);
      setLoading(false); requestLoading = false;
    }
    else {
      console.log(`Biotest Loading Error ${json.code}: ${json.message}`);
    }
  };

  const generatePreview = (fitnessClientId, answers, syncF = false) => {
    let newPreviewData = {};

    questions.forEach( item => {
      if( answers[item.id] != null )
      switch (item.type) {
        case 0: // openInput
          if( answers[item.id]  != '') newPreviewData[item.id] = answers[item.id];
          break;

        case 1: // switch
          newPreviewData[item.id] = item.option[answers[item.id]];
          break;

        case 2: // select
          newPreviewData[item.id] = item.options[answers[item.id] - 1];
          break;

        case 3: // sub-select
        case 9: // special-sub-select
          newPreviewData[item.id] = {};

          Object.keys(answers[item.id]).forEach( subItemKey => {
            let index = Number.isInteger(item.subQuestions[subItemKey][1]);
            let commonOptions = Array.isArray(item.commonOptions[0]) ? item.commonOptions[index ? item.subQuestions[subItemKey][1] : 0] : item.commonOptions;
            newPreviewData[item.id][subItemKey] = commonOptions[answers[item.id][subItemKey] - 1];
          });
          break;

        case 4: // linearLevel
          newPreviewData[item.id] = item.represent[answers[item.id]];
          break;

        case 8: // sub-openInput
          newPreviewData[item.id] = {};

          Object.keys(answers[item.id]).forEach( subItemKey => {
            if( answers[item.id]  != '')
              newPreviewData[item.id][subItemKey] = answers[item.id][subItemKey];
          });
          break;

        default:
          break;
      }
    });

    setLoading(false);

    fetch(`${config.api_prefix}/fitnessClient/${fitnessClientId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }).then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
            newPreviewData = {
            ...newPreviewData,
            name: `${json.data.name.surname} ${json.data.name.givename}`,
            email: json.data.email,
            gender: json.data.gender,
            memberNumber: json.data.memberNumber,
            phoneNumber: json.data.phoneNumber,
          };

          setPreviewData(newPreviewData);
          syncF ? sync(newPreviewData) : false;
        }
        else {
          console.log(`Fitness Client Loading Error ${json.code}: ${json.message}`);
          setPreviewData(newPreviewData);
        }
      })
      .catch((error) => console.error(error));
  }

  const sync = previewData => {
    fetch(`${config.api_prefix}/biotest/${biotestId}/check`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ preview: previewData }),
    }).then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          setStage(3); // sync-ed
          requestLoading = false;
        }
        else {
          // Show message
          console.log(`Error ${json.code}: ${json.message}`);
        }
      })
      .catch((error) => console.error(error));
  }

  const generateReport = () => {
    if(requestLoading) return console.log('Loading');

    requestLoading = true;
    fetch(`${config.api_prefix}/biotest/${biotestId}/generateReport`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    }).then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          setStage(4); // sync-ed
          navigation.navigate('BiotestHistoryStack', {
            screen: 'BiotestStack',
            params: {
              screen: 'BiotestReport',
              params: { biotestId },
            }
          }); // sync-ed
        }
        else {
          // Show message
          console.log(`Error ${json.code}: ${json.message}`);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {requestLoading = false});
  }

  useEffect( () => {
    if( isFocused == false ) return false; // leave page => nothing to do
      biotestId = route.params.biotestId;

      const path = route.path;
      if( path && path.startsWith("/biotest/new/") ) return navigation.goBack();
      if(!route.params.fitnessClientId || !route.params.answers || !route.params.language) return loadData(biotestId, callback);

      fitnessClientId = route.params.fitnessClientId;
      answers = route.params.answers;

      setReportLanguage(route.params.language);
      generatePreview(fitnessClientId, answers, true);
  // refresh every load
  }, [isFocused, route.params.biotestId]);

  const TitleLayout = ({ title, divider = true}) => ( title ?
    <>
      { divider ? <View style={{ borderWidth: 1, marginTop: 15, borderColor: '#0C7036'}} /> : <></> }
      <View style={styles.titleShell}>
        <Text style={styles.title}>{langLoader.get(title)}</Text>
      </View>
    </>
  :
    <></>
  )

  const questionLayouts = {
    normal: ({ item, questionNumber }) => ( previewData[item.id] ? (
        <>
          <TitleLayout title={item.title} />
          <Text style={biotestPreviewStyles.question}>Q{questionNumber}. {item.question}</Text>
          <Text style={biotestPreviewStyles.answer}>{previewData[item.id]}{item.unit ? ` ${item.unit}`: '' }</Text>
        </>
      ) : false
    ),
    'sub-select': ({ item, questionNumber }) => {
      const questionObj = item;
      let subQuestionCheck = previewData[questionObj.id] != null;

      return ( subQuestionCheck ? (
          <>
            <TitleLayout title={item.title} />
            <Text style={biotestPreviewStyles.question}>Q{questionNumber}. {questionObj.question}</Text>
            <FlatList
              data={Object.keys(previewData[questionObj.id])}
              renderItem={ ({ item }) => {
                let subQuestion = Array.isArray(questionObj.subQuestions[item]) ? questionObj.subQuestions[item][0] : questionObj.subQuestions[item];
                return <Text style={biotestPreviewStyles.answer}>{subQuestion}: {previewData[questionObj.id][item]}{questionObj.unit ? ` ${questionObj.unit}`: '' }</Text>
              } }
              keyExtractor={item => item}
            />
          </>
        ) : false
      );
    },
    linearLevel: ({ item, questionNumber }) => ( previewData[item.id] ? (
        <>
          <TitleLayout title={item.title} />
          <Text style={biotestPreviewStyles.question}>Q{questionNumber}. {item.question}</Text>
          <LinearLevelPreview
            label={item.label}
            data={item.represent}
            defaultIndex={answers[item.id]}
          />
        </>
      ) : false
    ),
  };

  const questionLayout = ({ item, index }) => {
    const questionNumber = index + 1;
    switch(item.type){
      case 0:
      case 1:
      case 2:
        return questionLayouts.normal({ item, questionNumber });

      case 3:
      case 8:
      case 9:
        return questionLayouts['sub-select']({ item, questionNumber });

      case 4:
        return questionLayouts.linearLevel({ item, questionNumber });

      default:
        return (<></>);
    }
  };

  return isLoading ? <LoadingScreen /> : (
    <View style={styles.view}>
      <ConfirmationBox
        visible={generateReportConfirm}
        title={langLoader.get('generateReportMessage')}
        data={[
          {value: langLoader.get('yesButton'), color: "#219700"},
          {value: langLoader.get('noButton'), color: "#AAA"},
        ]}
        callback={ value => ( value == 0 ? generateReport() : 0 ) }
      />
      <View style={[styles.viewInner, { paddingTop: 50, paddingBottom: 50 }]}>
        <TitleLayout title="header-personalInformation" divider={false} />
        <View style={{ flexDirection: 'row', marginTop: 15 }}>
          <View style={{ flexDirection: 'row', flex: 2 }}>
          <Text style={{ fontWeight: 'bold', paddingRight: 10 }}>{langLoader.get('name')}</Text>
          <Text>{previewData.name}</Text>
          </View>

          <View style={{ flexDirection: 'row', flex: 2 }}>
          <Text style={{ fontWeight: 'bold', paddingRight: 10 }}>{langLoader.get('gender')}</Text>
          <Text>{previewData.gender}</Text>
          </View>
        </View>

        <View style={{ flexDirection: 'row', marginTop: 15 }}>
          <View style={{ flexDirection: 'row', flex: 2 }}>
          <Text style={{ fontWeight: 'bold', paddingRight: 10 }}>{langLoader.get('mobile')}</Text>
          <Text>{previewData.phoneNumber}</Text>
          </View>

          <View style={{ flexDirection: 'row', flex: 2 }}>
          <Text style={{ fontWeight: 'bold', paddingRight: 10 }}>{langLoader.get('memberNumber-short')}</Text>
          <Text>{previewData.memberNumber}</Text>
          </View>
        </View>

        <Text style={biotestPreviewStyles.question}>{langLoader.get('email')}</Text>
        <Text style={biotestPreviewStyles.answer}>{previewData.email}</Text>

        {/*<View style={{ borderWidth: 1, marginTop: 15, borderColor: '#0C7036'}} />*/}

        <FlatList
          data={questions}
          renderItem={questionLayout}
          keyExtractor={item => item.id}
        />

        { reportLanguage ? <>
        <TitleLayout title="header-reportLanguage" />
        <Text style={biotestPreviewStyles.answer}>{reportLanguageSelectOption.find( item => ( item.name == reportLanguage ) ).value}</Text>
        </> : <></> }

        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Pressable
            style={({ pressed }) => [styles.button, stage != 4 ? ({ opacity: pressed ? 0.2 : 1 }) : biotestPreviewStyles.disableButton]}
            onPress={ () => ( stage != 4 ? navigation.navigate('CreateBiotest', { biotestId, fitnessClientId, language: reportLanguage, answers }) : false ) }
          >
            <Text style={styles.buttonText}>{langLoader.get('editButton')}</Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [styles.button, stage >= 3 ? ({ opacity: pressed ? 0.2 : 1 }) : biotestPreviewStyles.disableButton]}
            onPress={ () => {
              switch(stage) {
                case 4:
                  navigation.navigate('BiotestReport', { biotestId })
                  break;

                case 3:
                  generateReportConfirm.setVisible(true);

                case 2:
                case 1:
                default:
                  break;
              };
            }}
          >
            <Text style={styles.buttonText}>{langLoader.get('reportButton')}</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

const biotestPreviewStyles = StyleSheet.create({
  question: {
    fontWeight: 'bold',
    marginTop: 15,
  },
  answer: {
    marginTop: 5,
    marginLeft: 15,

  },
  disableButton: {
    backgroundColor: "#AAA",
    cursor: 'default',
  },
});

export default BiotestPreviewScreen;

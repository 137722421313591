import * as React from 'react';
import MainApp from './src/main';

import { LanguageContext, IsAdminContext, ScreensContext } from './src/context';
import ScreensContextInit from './src/screensContextInit';

export default function App() {
  let o={}; // temp
  const language = ( o = {}, [o.get, o.set] = React.useState(["en"]), o); // default language
  const [isAdmin, setAdminFlag] = React.useState(false);

  return (
    <LanguageContext.Provider value={ language }>
    <IsAdminContext.Provider value={{ isAdmin, setAdminFlag }}>
    <ScreensContext.Provider value={ ScreensContextInit }>
      <MainApp />
    </ScreensContext.Provider>
    </IsAdminContext.Provider>
    </LanguageContext.Provider>
  );
}


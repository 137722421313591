import * as React from 'react';
import { Image } from 'react-native';

import { NavigationContainer, getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
// import { HeaderBackButton } from  '@react-navigation/elements';

import LanguageResourceLoader from './language';
import { LanguageContext, IsAdminContext } from './context';

import CustomDrawerContent from './customDrawerContent';
import Linking from './deepLink';

import HomeScreen from './screens/home';

import UserDashboardScreen from './screens/user/dashboard';
import UserProfileScreen from './screens/user/profile';
import UserCreateScreen from './screens/user/create';
import UserEditScreen from './screens/user/edit';
import ReportSettingScreen from './screens/user/reportSetting';
import ChangePasswordScreen from './screens/user/changePassword';

import BiotestDashboardScreen from './screens/biotest/dashboard';
import BiotestCreateScreen from './screens/biotest/create';
import BiotestFormScreen from './screens/biotest/form';
import BiotestPreviewScreen from './screens/biotest/preview';
import BiotestReportScreen from './screens/biotest/report';

import FitnessClientProfileScreen from './screens/fitnessClient/profile';
import FitnessClientCreateScreen from './screens/fitnessClient/create';
import FitnessClientEditScreen from './screens/fitnessClient/edit';

import LoadingScreen from './screens/loading';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const langLoader = new LanguageResourceLoader('', ['en', 'zh']);
let isAdmin = false;

const UserProfileStack = ({ navigation, route }) => {
  React.useLayoutEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);
    navigation.setOptions({ headerShown: ( !routeName || routeName == 'Profile' ) ? true : false });
  }, [navigation, route]);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Profile"
        component={UserProfileScreen}
        initialParams={{ userId: 'me' }}
        options={{
          headerShown: false,
          title: langLoader.get('profile'),
        }}
      />
      <Stack.Screen
        name="EditUser"
        component={UserEditScreen}
        options={{
          title: langLoader.get('editUser'),
        }}
      />
      <Stack.Screen
        name="ChangePassword"
        component={ChangePasswordScreen}
        options={{
          title: langLoader.get('changePassword'),
        }}
      />
      <Stack.Screen
        name="ReportSetting"
        component={ReportSettingScreen}
        options={{
          title: langLoader.get('reportSetting'),
        }}
      />
    </Stack.Navigator>
  );
};

const UserManagementStack = ({ navigation, route }) => {
  React.useLayoutEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);
    navigation.setOptions({ headerShown: ( !routeName || routeName == 'UserDashboard' ) ? true : false });
  }, [navigation, route]);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="UserDashboard"
        component={UserDashboardScreen}
        options={{
          headerShown: false,
          title: langLoader.get('userManagement'),
        }}
      />
      <Stack.Screen
        name="CreateUser"
        component={UserCreateScreen}
        options={{
          title: langLoader.get('createUser'),
        }}
      />
      <Stack.Screen
        name="UsersProfileStack"
        component={UserProfileStack}
        options={({route})=>({
          // First Page: User Profile
          title: `${langLoader.get('userProfile')} ${ route.params ? route.params.params.username : ''}`,
        })}
      />
    </Stack.Navigator>
  );
};

const FitnessClientStack = ({ navigation, route }) => {
  React.useLayoutEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);
    navigation.setOptions({ headerShown: ( !routeName || routeName == 'FitnessClientProfile' ) ? true : false });
  }, [navigation, route]);

  return (
    <Stack.Navigator>
      <Drawer.Screen
        name="FitnessClientProfile"
        component={FitnessClientProfileScreen}
        options={{
          headerShown: false,
          title: `Fitness Client Profile`,
        }}
      />
      <Stack.Screen
        name="CreateFitnessClient"
        component={FitnessClientCreateScreen}
        options={{
          title: langLoader.get('createFitnessClient'),
        }}
      />
      <Stack.Screen
        name="EditFitnessClient"
        component={FitnessClientEditScreen}
        options={({route})=>({
          title: langLoader.get('editFitnessClient'),
        })}
      />
    </Stack.Navigator>
  );
};

const BiotestStack = ({ navigation, route }) => {
  React.useLayoutEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);
    navigation.setOptions({ headerShown: ( !routeName || routeName == 'CreateBiotest' ) ? true : false });
  }, [navigation, route]);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="CreateBiotest"
        component={BiotestCreateScreen}
        // initialParams={{ biotestId: null }}
        options={{
          headerShown: false,
          title: langLoader.get('createBiotest'),
        }}
      />
      <Stack.Screen
        name="BiotestForm"
        component={BiotestFormScreen}
        options={{
          title: langLoader.get('BiotestForm'),
        }}
      />
      <Stack.Screen
        name="BiotestPreview"
        component={BiotestPreviewScreen}
        options={{
          title: langLoader.get('BiotestPreview'),
          /*headerLeft : props => (
            <HeaderBackButton
              {...props}
               onPress={ () => navigation.goBack() }
            />
          ),
          */
        }}
      />
      <Stack.Screen
        name="BiotestReport"
        component={BiotestReportScreen}
        options={{
          title: langLoader.get('BiotestReport'),
          /*headerLeft : props => (
            <HeaderBackButton
              {...props}
               onPress={ () => navigation.goBack() }
            />
          ),
          */
        }}
      />
      <Stack.Screen
        name="FitnessClientStack"
        component={FitnessClientStack}
        options={({route})=>({
          // First Page: Fitness Client Profile
          title: `Fitness Client Profile: ${route.params ? route.params.name : ''}`,
        })}
      />
    </Stack.Navigator>
  );
};

const BiotestHistoryStack = ({ navigation, route }) => {
  React.useLayoutEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);
    navigation.setOptions({ headerShown: ( !routeName || routeName == 'BiotestDashboard' ) ? true : false });
  }, [navigation, route]);

  return (
    <Stack.Navigator>
      <Drawer.Screen
        name="BiotestDashboard"
        component={BiotestDashboardScreen}
        options={{
          headerShown: false,
          title: langLoader.get('biotestDashboard'),
        }}
      />
      <Drawer.Screen
        name="BiotestStack"
        component={BiotestStack}
        options={{
          // First Page: Create Biotest
          title: `Edit Biotest`, // langLoader.get('createBiotest'),
          headerTitle: `Edit Biotest`, // langLoader.get('createBiotestHeader'),
        }}
      />
    </Stack.Navigator>
  );
};

const SecureDrawer = () => {
  return (
    <Drawer.Navigator
      useLegacyImplementation
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      screenOptions={{
        drawerActiveTintColor: '#FFF',
        drawerActiveBackgroundColor: '#0C7036',
      }}
    >
      <Drawer.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: langLoader.get('home'),
          // headerTitle: 'SPORTS LAB',
          headerTitle: props => (
            <Image style={{ height: 50, width: 213 }} source={require('./assets/img/logo3.scale.png')} />
          ),
        }}
      />
      <Drawer.Screen
        name="CreateBiotestStack"
        component={BiotestStack}
        options={{
          // First Page: Create Biotest
          unmountOnBlur: true,
          title: langLoader.get('createBiotest'),
          headerTitle: langLoader.get('createBiotestHeader'),
        }}
      />
      <Drawer.Screen
        name="BiotestHistoryStack"
        component={BiotestHistoryStack}
        options={{
          // First Page: Biotest Dashboard
          title: langLoader.get('biotestDashboard'),
          headerTitle: langLoader.get('biotestDashboardHeader'),
        }}
      />
      <Stack.Screen
        name="ProfileStack"
        component={UserProfileStack}
        options={{
          // First Page: My Profile
          title: langLoader.get('profile'),
          headerTitle: langLoader.get('profileHeader'),
        }}
      />
      {
          isAdmin ?
      <Drawer.Screen
        name="UserManagementStack"
        component={UserManagementStack}
        options={{
          // First Page: User Dashboard
          title: langLoader.get('userManagement'),
          headerTitle: langLoader.get('userManagementHeader'),
        }}
      />
      : false }
    </Drawer.Navigator>
  );
};

export default () => {
  isAdmin = React.useContext(IsAdminContext).isAdmin;
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  return (
    <NavigationContainer
      linking={Linking} fallback={LoadingScreen}
      documentTitle={{
        formatter: (options, route) =>
          `${options?.title ?? route?.name} - SportsLab Biotest Platform`,
      }}
    >
      <SecureDrawer />
    </NavigationContainer>
  );
};

import React, { useEffect, useState } from 'react';
import { StyleSheet, View, FlatList, TouchableOpacity, Text, TextInput } from 'react-native';
import LanguageResourceLoader from '../../language';

import config from '../../config';
import styles from '../../styles';
import { LanguageContext, ScreensContext } from '../../context';

import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import MIcon from 'react-native-vector-icons/MaterialIcons';

import ConfirmationBox from '../../components/ConfirmationBox';

import LoadingScreen from '../loading';

const langLoader = new LanguageResourceLoader('user.dashboard', ['en', 'zh']);
let loadingRoles = {};

const AccountManageScreen = ({ navigation }) => {
  let thisScreenContext = React.useContext(ScreensContext).user.dashboard;
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  const [isLoading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState({});

  const [keyword, setKeyword] = useState('');
  const [loadingMore, setLoadingMore] = useState(true);
  const [nextPage, setNextPage] = useState(0);

  let o={}; // temp
  const deleteConfirm = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const deleteSuccess = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const [selectedUser, setSelectedUser] = useState('');

  const getRoleCode = roleId => {
    fetch(`${config.api_prefix}/role/${roleId}`, { credentials: 'include' })
      .then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          setRoles({
            ...roles,
            [roleId]: json.data.name,
          });
          delete loadingRoles[roleId];
        }
        else {
          // Show message
          console.log('invalid role Id');
        }
      })
      .catch((error) => console.error(error));
  };

  const getUsers = ( keyword = '', append = true, pageIndex = 0, pageLength = 3 ) => {
    fetch(`${config.api_prefix}/user?pageLength=${pageLength}&pageIndex=${pageIndex}` + ( keyword ? `&keyword=${keyword}`: '' ), { credentials: 'include' })
      .then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          setLoadingMore( ( pageIndex * pageLength + json.data.length ) < json.total );

          setUsers([
            ...( append ? users : [] ),
            ...json.data,
          ]);
          setNextPage( pageIndex + 1 );
          setLoading(false);
        }
        else {
          // Show message
          console.log('invalid userId');
        }
      })
      .catch((error) => console.error(error));
  };

  const deleteUser = id => {
    fetch(`${config.api_prefix}/user/${id}`, {
      method: 'DELETE',
      credentials: 'include',
    }).then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          deleteSuccess.setVisible(true);
        }
        else {
          // Show message
          console.log('invalid userId');
        }
      })
      .catch((error) => console.error(error));
  };

  const search = keyword => {
    setKeyword(keyword);
    getUsers(keyword, false); // state keyword update will delay, local function keyword will be the most update one
  };

  const refresh = () => getUsers(keyword, false);

  useEffect( () => {
    getUsers('', false);
    thisScreenContext.refresh = refresh;
    // Runs ONCE after initial rendering
    // and after every rendering ONLY IF `prop` or `state` changes
  }, []);

  const userLayout = ({ item }) => {
    if(!roles[item.roleId] && !loadingRoles[item.roleId]){
      loadingRoles[item.roleId] = true;
      getRoleCode(item.roleId);
    }
    const adminFlag = roles[item.roleId] == config.internal.adminPermission ? true: false

    return (
      <View style={[userStyles.card, adminFlag ? userStyles.cardAdmin : userStyles.cardUser]}>
        <View style={userStyles.head}>
          <Text style={[
            userStyles.headText,
            adminFlag ? userStyles.headTextAdmin : userStyles.headTextUser
          ]} >{item._id}</Text>
          <Text style={[
            userStyles.headText,
            adminFlag ? userStyles.headTextAdmin : userStyles.headTextUser
          ]} >{roles[item.roleId]}</Text>
        </View>
        <Text style={styles.header}>{item.username}</Text>
        {/*<Text style={{ fontSize: 16 }}>Name: { item.name ? `${item.name.surname} ${item.name.givename}` : "----- ----- -----" }</Text>*/}
        <Text style={{ fontSize: 16 }}>{langLoader.get('email')}: {item.email}</Text>
        <View style={userStyles.bottom}>
          <View style={{justifyContent: 'flex-end'}}>
            <Text style={userStyles.date}>{langLoader.get('createdAt')}: {(new Date(item.created.At)).toLocaleString('zh', { timeZone: 'Asia/Hong_Kong' })}</Text>
            <Text style={userStyles.date}>{langLoader.get('updatedAt')}: {(new Date(item.updated.At)).toLocaleString('zh', { timeZone: 'Asia/Hong_Kong' })}</Text>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-end', height: 42 }}>
            <TouchableOpacity>
              <MCIcon
                name="account-details"
                size={30}
                color="#2196F3"
                style={styles.iconButton}
                onPress={ () => navigation.navigate('UsersProfileStack', {
                  screen: 'Profile',
                  params: { userId: item._id, username: item.username }
                }) }
              />
            </TouchableOpacity>
            <TouchableOpacity>
              <MCIcon
                name="delete"
                size={30}
                color="#2196F3"
                style={styles.iconButton}
                onPress={ () => {
                  setSelectedUser({ id: item._id, username: item.username });
                  deleteConfirm.setVisible(true);
                }}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  };

  return isLoading ? <LoadingScreen /> : (
    <View style={styles.view}>
      <ConfirmationBox
        visible={deleteConfirm}
        title={langLoader.get('isDeleteUserMessage') ? `${langLoader.get('isDeleteUserMessage')[0]}${selectedUser.username}${langLoader.get('isDeleteUserMessage')[1]}`: ''}
        data={[
          {value: langLoader.get('yesButton'), color: "#219700"},
          {value: langLoader.get('noButton'), color: "#AAA"},
        ]}
        callback={ value => { (value == 0) ? deleteUser(selectedUser.id): 0 }}
      />
      <ConfirmationBox
        visible={deleteSuccess}
        title={langLoader.get('deleteUserMessage') ? `${langLoader.get('deleteUserMessage')[0]}${selectedUser.username}${langLoader.get('deleteUserMessage')[1]}`: ''}
        data={[
          {value: langLoader.get('confirmButton'), color: "#219700"},
        ]}
        callback={refresh}
        defaultIndex={0}
      />
      <View style= {{ flexDirection: 'row', justifyContent: 'space-between', width: 350, padding: 15 }}>
        <TouchableOpacity>
          <MIcon
            name="person-add-alt-1"
            size={30}
            color="#2196F3"
            style={styles.iconButton}
            onPress={ () => navigation.navigate('CreateUser') }
          />
        </TouchableOpacity>
        <View style= {{ flexDirection: 'row', justifyContent: 'flex-end'}}>
          <TextInput
            style={{ borderWidth: 1, borderRadius: 5, margin: 5, padding: 5 }}
            autoComplete="on"
            onChange={ e => search(e.target.value) }
            onKeyPress={ e => (e.key === 'Enter') ? search(keyword): false }
          />
          <TouchableOpacity>
            <MIcon
              name="search"
              size={30}
              color="#2196F3"
              style={styles.iconButton}
              onPress={ () => search(keyword) }
            />
          </TouchableOpacity>
        </View>
      </View>
      <FlatList
        ListFooterComponent={
          <View>
            {loadingMore &&
              <Text>Loading More...</Text>
            }
          </View>
        }
        scrollEventThrottle={250}
        onEndReached={info => {
          console.log(info);
          loadingMore ? getUsers(keyword, true, nextPage): console.log(nextPage);
        }}
        onEndReachedThreshold={0.01}
        data={users}
        renderItem={userLayout}
        keyExtractor={user => user._id}
        style={{width: 350, padding: 15 }}
      />
    </View>
  );

};

const userStyles = StyleSheet.create({
  card: {
    borderWidth: 2,
    borderRadius: 10,
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    height: 125,
  },
  cardAdmin: {
    borderColor: 'rgba(255, 102, 102, 0.8)',
    backgroundColor: 'rgba(255, 221, 221, 0.4)',
  },
  cardUser:{
    borderColor: 'rgba(101, 156, 210, 0.8)',
    backgroundColor: 'rgba(219, 237, 255, 0.4)',
  },
  head: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 12,
    fontWeight: 'bold',
  },
  headText: {
    fontWeight: 'bold',
    paddingLeft: 3,
    paddingRight: 3,
  },
  headTextAdmin: {
    backgroundColor: 'rgba(255, 102, 102, 0.8)',
  },
  headTextUser: {
    backgroundColor: 'rgba(101, 156, 210, 0.8)',
  },
  bottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 5,
  },
  date: {
    fontWeight: 'bold',
    fontSize: 11,
    color: '#AAA',
  }
});

export default AccountManageScreen;
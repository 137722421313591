import React, { useEffect, useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Image, Text } from 'react-native';
import LanguageResourceLoader from '../../language';

import config from '../../config';
import styles from '../../styles';
import { LanguageContext, ScreensContext } from '../../context';

// import Icon from 'react-native-vector-icons/FontAwesome5';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';

import ConfirmationBox from '../../components/ConfirmationBox';

import LoadingScreen from '../loading';

const langLoader = new LanguageResourceLoader('user', ['en', 'zh']);

const ProfileScreen = ({ route, navigation }) => {
  let thisScreenContext = React.useContext(ScreensContext).user.profile;
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [imageCheckBox, setImageCheckBox] = useState(false);

  const [userImage, setUserImage] = useState(require('../../assets/img/default_user_img.png')); // defaultImage

  let o={}; // temp
  const errorConfirm = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const editImageSuccess = ( o = {}, [o.visible, o.setVisible] = useState(false), o);

  const selectNewUserImage = e => {
    const file = e.target.files[0];

    const img = document.createElement("img");
    img.height = 60;
    img.onload = () => {
      URL.revokeObjectURL(img.src);
    }

    if (file.type && file.type.indexOf('image/png') === -1) {
      console.log('File is not an PNG image.');
      setErrorMessage(langLoader.get('updateImageErrorWithType'));
      errorConfirm.setVisible(true);
      return;
    }
    if (file.size > (500 * 1024)) {
      console.log('File over 500 kb.');
      setErrorMessage(langLoader.get('updateImageErrorWithSize'));
      errorConfirm.setVisible(true);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      setUserImage({ uri: event.target.result });
      setImageCheckBox(true);
    });
    reader.readAsDataURL(file);
  };

  const updateUserImage = () => {
    console.log(userImage.uri)
    fetch(`${config.api_prefix}/user/${user._id}/updatePersonalImage`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ image: userImage.uri}),
    }).then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          setImageCheckBox(false);
          editImageSuccess.setVisible(true);
        }
        else {
          // Show message
          console.log(`Error ${json.code}: ${json.message}`);
        }
      })
      .catch((error) => console.error(error));
  }

  const getUserDetail = userId => {
    fetch(`${config.api_prefix}/user/${userId}`, { credentials: 'include' })
      .then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          setUser(json.data);
          if(json.data.personalImage) setUserImage({ uri: json.data.personalImage });
          setLoading(false);
        }
        else {
          // Show message
          console.log('invalid userId');
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect( () => {
    getUserDetail(route.params.userId);
    thisScreenContext.updateUser = setUser;
    // Runs ONCE after initial rendering
    // and after every rendering ONLY IF `prop` or `state` changes
  }, [route.params.userId]);

  return isLoading ? <LoadingScreen /> : (
    <View style={styles.view}>
      <ConfirmationBox
        visible={errorConfirm}
        title={errorMessage}
        data={[
          {value: langLoader.get('confirmButton'), color: "#219700"},
        ]}
      />
      <ConfirmationBox
        visible={editImageSuccess}
        title={langLoader.get('editImageSuccess')}
        data={[
          {value: langLoader.get('confirmButton'), color: "#219700"},
        ]}
      />
      <View style={profileStyles.shell}>
        <Image
          style={profileStyles.default_user_img}
          source={userImage}
        />
        <View style={{ position: "absolute", bottom: 0, backgroundColor: "#000", opacity: 0.25, width: "100%", height: 50 }} />
        <View style={{ position: "absolute", bottom: 0,  width: "100%", height: 50, justifyContent: 'flex-end', flexDirection: "row" }}>
          <label style={{ cursor: 'pointer' }}>
            <MCIcon
              name="image-edit"
              size={50}
              color="#2196F3"
              style={styles.iconButton}
            />
            <input type="file" onChange={selectNewUserImage} accept="image/png" style={{ display: "none" }}/>
          </label>
          { imageCheckBox ?
          <TouchableOpacity>
            <MCIcon
              name="check"
              size={50}
              color="#2196F3"
              style={[styles.iconButton, { marginTop: 0, marginBottom: 0 }]}
              onPress={updateUserImage}
            />
          </TouchableOpacity>
          : <></> }
        </View>
      </View>
      <Text style={styles.text}>User ID: {user._id}</Text>
      <View style={styles.viewInner} >
        <View style={{ justifyContent: 'flex-end', flexDirection: "row", height: 42 }} >
          <TouchableOpacity>
            <MCIcon
              name="form-textbox-password"
              size={30}
              color="#2196F3"
              style={styles.iconButton}
              onPress={ () => navigation.navigate('ChangePassword', { userId: route.params.userId }) }
            />
          </TouchableOpacity>
          <TouchableOpacity>
            <MCIcon
              name="account-edit"
              size={30}
              color="#2196F3"
              style={styles.iconButton}
              onPress={ () => navigation.navigate('EditUser', { user: user, userId: route.params.userId }) }
            />
          </TouchableOpacity>
        </View>
        <Text style={styles.text}>{langLoader.get('email')}: {user.email}</Text>
        <Text style={styles.text}>{langLoader.get('mobile')}: { user.phoneNumber ? `${user.phoneNumber.slice(0,4)} ${user.phoneNumber.slice(4,8)}` : "---- ----" }</Text>
        <Text style={styles.text}>{langLoader.get('name')}: { user.name ? `${user.name.surname} ${user.name.givename}` : "----- ----- -----" }</Text>
        <Text style={styles.text}>{langLoader.get('gender')}: { user.gender ? user.gender : langLoader.get('genderNotSet') }</Text>
        <Text style={styles.text}>{langLoader.get('company')}: { user.company ? user.company : langLoader.get('genderNotSet') }</Text>
        <Text style={styles.text}>{langLoader.get('reportUsage')}: {"???"}</Text>
      </View>
    </View>
  );
};

const profileStyles = StyleSheet.create({
  shell: {
    height: 300,
    width: 300,
    margin: 40,
  },
  default_user_img: {
    height: '100%',
    width: '100%',
    // margin: 40,
    borderRadius: 200,
    position: "absolute",
  },
});

export default ProfileScreen;


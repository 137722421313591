import React, { useState } from 'react';
import { View, Pressable, Text, TextInput } from 'react-native';
import LanguageResourceLoader from '../../language';

import config from '../../config';
import styles from '../../styles';
import { LanguageContext, ScreensContext } from '../../context';

import ConfirmationBox from '../../components/ConfirmationBox';
import SelectMenu from '../../components/SelectMenu';
import SelectView from '../../components/SelectView';

const langLoader = new LanguageResourceLoader('fitnessclient', ['en', 'zh']);

const FitnessClientCreateScreen = ({ navigation, route }) => {
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  const [memberNumber, setMemberNumber] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [surname, setSurname] = useState('');
  const [givename, setGivename] = useState('');

  const genderSelectOption = [
    { value: langLoader.get('genderDefaultSelect') },
    { value: "M" },
    { value: "F" },
  ];
  const [gender, setGender] = useState(0);

  const [message, setMessage] = useState(null);

  let o={}; // temp
  const confirm = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const genderSelect = ( o = {}, [o.visible, o.setVisible] = useState(false), o);

  const biotestCreateScreenContext = React.useContext(ScreensContext).biotest?.create;

  const createFitnessClient = () => {
    const data = {
      ...(memberNumber && { memberNumber }),
      ...(email && { email }),
      ...(phoneNumber && { phoneNumber }),
      ...(surname && givename && {
        name: {
          surname,
          givename,
        },
      }),
      ...(gender && { gender: genderSelectOption[gender].value }),
    };

    if( (surname == '' || givename == '') && !(surname == '' && givename == '')) // surname xor givename
      return setMessage(langLoader.get('messageNeedNames'));

    fetch(`${config.api_prefix}/fitnessClient`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(data),
    }).then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          if (typeof biotestCreateScreenContext?.loadAndSetFitnessClientId == 'function') biotestCreateScreenContext.loadAndSetFitnessClientId(json.data._id);
          confirm.setVisible(true);
        }
        else {
          // Show message
          setMessage(`Error ${json.code}: ${json.message}`);
        }
      })
      .catch((error) => console.error(error));
  };

  const submitForm = createFitnessClient;

  return (
    <View style={styles.view}>
      <ConfirmationBox
        visible={confirm}
        title={langLoader.get('createFitnessClientConfirmMessage')}
        data={[
          {value: langLoader.get('confirmButton'), color: "#219700"},
        ]}
        callback={ () => navigation.goBack() }
        defaultIndex={0}
      />
      <SelectMenu
        visible={genderSelect}
        data={genderSelectOption}
        callback={setGender}
        selectedIndex={gender}
      />
      <View style={styles.viewInner}>
        <Text>{langLoader.get('memberNumber')}</Text>
        <TextInput
          style={styles.input}
          autoComplete="memberNumber"
          onChange={ e => setMemberNumber(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('email')}</Text>
        <TextInput
          style={styles.input}
          autoComplete="email"
          onChange={ e => setEmail(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('surname')}</Text>
        <TextInput
          value={surname}
          style={styles.input}
          autoComplete="name-family"
          onChange={ e => setSurname(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('givename')}</Text>
        <TextInput
          value={givename}
          style={styles.input}
          autoComplete="name-given"
          onChange={ e => setGivename(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('mobile')}</Text>
        <TextInput
          value={phoneNumber}
          style={styles.input}
          autoComplete="tel"
          onChange={ e => setPhoneNumber(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('gender')}</Text>
        <SelectView
          value={genderSelectOption[gender].value}
          onPress={() => genderSelect.setVisible(true)}
          selecting={genderSelect.visible}
        />

        <Text style={styles.warningMessage} >{message}</Text>
        <Pressable
          style={({ pressed }) => [styles.button, { opacity: pressed ? 0.2 : 1 }]}
          onPress={submitForm}
        >
          <Text style={styles.buttonText}>{langLoader.get('submitFormButton') ? langLoader.get('submitFormButton').toUpperCase() : ''}</Text>
        </Pressable>
      </View>
    </View>
  );
};

export default FitnessClientCreateScreen;

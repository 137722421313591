import React, { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView, View, Pressable, TouchableOpacity, Text, TextInput } from 'react-native';
import LanguageResourceLoader from '../../language';

import config from '../../config';
import styles from '../../styles';
import { LanguageContext, ScreensContext } from '../../context';

import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import MIcon from 'react-native-vector-icons/MaterialIcons';

import SelectMenu from '../../components/SelectMenu';
import SelectMenuWithSearch from '../../components/SelectMenuWithSearch';
import SelectView from '../../components/SelectView';

import LoadingScreen from '../loading';

import { loadData } from './loadData';

const langLoader = new LanguageResourceLoader('biotest', ['en', 'zh']);
let selectedFitnessClient = {}; // Selected fitnessClient object
let biotestId, initFitnessClientId, initReportLanguage, answers,
  requestLoading = true;

const varInit = (i_biotestId = null, fitnessClientId = null, reportLanguage = null, i_answers = null) => {
  biotestId = i_biotestId;
  initFitnessClientId = fitnessClientId;
  initReportLanguage = reportLanguage;
  answers = i_answers;
};

const AddBiotestScreen = ({ navigation, route }) => {
  let thisScreenContext = React.useContext(ScreensContext).biotest.create;
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  const [isLoading, setLoading] = useState(true);

  const defaultFitnessClient = {
    name: "0",
    value: langLoader.get('fitnessClientDefaultSelect'),
    data: {
      email: '',
      gender: '',
      name: {
        surname: '',
        givename: '',
      },
      phoneNumber: '',
      memberNumber: '',
    },
  };
  const [fitnessClientSelectOption, setFitnessClientSelectOption] = useState([ defaultFitnessClient ]);
  const [fitnessClientId, setFitnessClientId] = useState("0");

  const reportLanguageSelectOption = [
    {
      name: "en",
      value: langLoader.get('language-en'),
    },
    {
      name: "zh",
      value: langLoader.get('language-zh'),
    },
  ];
  const [reportLanguage, setReportLanguage] = useState(reportLanguageSelectOption.find( item => ( item.name == languages.get[0] ) ) ? languages.get[0] : "en" );

  const [message, setMessage] = useState(null);

  let o={}; // temp
  const fitnessClientSelect = ( o = {}, [o.visible, o.setVisible] = useState(false), o);
  const languageSelect = ( o = {}, [o.visible, o.setVisible] = useState(false), o);

  const handleLoadedFitnessClients = json => {
    if(json.code == 200){
      const fitnessClientsData = Array.isArray(json.data) ? json.data : [ json.data ];
      let loadedFitnessClients = [];

      fitnessClientsData.forEach( fitnessClient =>
        loadedFitnessClients.push({
          name: fitnessClient._id,
          value: !fitnessClient.deleted ? (fitnessClient.selfId ? 'Myself' : `${fitnessClient.name.surname} ${fitnessClient.name.givename} (${fitnessClient.phoneNumber})`) : `${fitnessClient._id} (deleted)`,
          data: {
            email: fitnessClient.email,
            gender: fitnessClient.gender,
            name: fitnessClient.name,
            phoneNumber: fitnessClient.phoneNumber,
            memberNumber: fitnessClient.memberNumber,
          },
        })
      );

      setFitnessClientSelectOption([
        defaultFitnessClient,
        ...loadedFitnessClients,
      ]);
    }
    else {
      console.log('invalid fitness client Id');
    }
  };

  const createBiotest = () => {
    if(fitnessClientId == "0") return setMessage('Please select fitness client.');

    const data = {
      ...(fitnessClientId != initFitnessClientId && { fitnessClientId }),
      ...(reportLanguage != initReportLanguage && { language: reportLanguage }),
    };

    if( Object.keys(data).length == 0 ) return navigation.navigate('BiotestForm', {
      biotestId,
      fitnessClientId,
      language: reportLanguage,
      ...( answers != null && { answers } ),
    });

    fetch(`${config.api_prefix}/biotest${ biotestId ? ('/' + biotestId ) : '' }`, {
      method: biotestId ? 'PUT': 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(data),
    }).then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          varInit(json.data._id, fitnessClientId, reportLanguage, answers);

          navigation.navigate('BiotestForm', {
            biotestId,
            fitnessClientId,
            language: reportLanguage,
            ...( answers != null && { answers } ),
          });
        }
        else {
          setMessage(`Error ${json.code}: ${json.message}`);
        }
      })
      .catch((error) => console.error(error));
  };

  const getFitnessClientsByKeword = keyword => {
    fetch(`${config.api_prefix}/fitnessClient${ keyword ? `?keyword=${keyword}`: '' }`, { credentials: 'include' })
      .then((response) => response.json())
      .then(handleLoadedFitnessClients)
      .catch((error) => console.error(error));
  };

  const getFitnessClientsById = fitnessClientId => {
    fetch(`${config.api_prefix}/fitnessClient/${fitnessClientId}`, { credentials: 'include' })
      .then((response) => response.json())
      .then(handleLoadedFitnessClients)
      .catch((error) => console.error(error));
  };

  const loadAndSetFitnessClientId = fitnessClientId => {
    setFitnessClientId(fitnessClientId);
    (fitnessClientId == "0") ? getFitnessClientsByKeword('') : getFitnessClientsById(fitnessClientId);
  }

  const submitForm = createBiotest;

  useEffect( () => {
    getFitnessClientsByKeword(''); // load Default Fitness Client List

    // Register callback function
    thisScreenContext.loadAndSetFitnessClientId = loadAndSetFitnessClientId;
    // need refresh every load
  }, []);

  useEffect( () => {
    if(!route.params?.biotestId) return ( console.log('New biotest') && varInit() && setLoading(false) );

    if(!route.params.fitnessClientId || !route.params.answers || !route.params.language)
      return loadData(biotestId, json => {
        if(json.code == 200 && Number(json.data.stage) <= 3) {
          varInit(route.params.biotestId, json.data.fitnessClientId, json.data.language, json.data.answers);
          loadAndSetFitnessClientId(json.data.fitnessClientId);

          setReportLanguage(json.data.language);
          setLoading(false);
        }
        else {
          console.log(`Biotest Loading Error ${json.code}: ${json.message}`);
          navigation.goBack();
        }
      });

    varInit(route.params.biotestId, route.params.fitnessClientId, route.params.language, route.params.answers)
    loadAndSetFitnessClientId(route.params.fitnessClientId);

    setReportLanguage(route.params.language);
    setLoading(false);
    // need refresh every load
  }, [route.params]);

  selectedFitnessClient = fitnessClientSelectOption.find( item => ( item.name == fitnessClientId ) );
  // loading values
  if (selectedFitnessClient == null) selectedFitnessClient = fitnessClientSelectOption[0];

  return (
    <SafeAreaView style={styles.view}>
    <ScrollView>
      <SelectMenuWithSearch
        visible={fitnessClientSelect}
        data={fitnessClientSelectOption}
        callback={setFitnessClientId}
        searchCallback={keyword => {
          setFitnessClientId("0"); // reset fitness Client
          getFitnessClientsByKeword(keyword);
        }}
        selectedIndex={fitnessClientId}
      />
      <SelectMenu
        visible={languageSelect}
        data={reportLanguageSelectOption}
        callback={setReportLanguage}
        selectedIndex={reportLanguage}
      />
      <View style={styles.viewInner}>
        <View style={styles.titleShell}>
          <Text style={styles.title}>{langLoader.get('header-personalInformation')}</Text>
        </View>

        <Text>{langLoader.get('fitnessClient')}</Text>
        <View style= {{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <SelectView
            value={selectedFitnessClient.value}
            onPress={() => fitnessClientSelect.setVisible(true)}
            selecting={fitnessClientSelect.visible}
            style={{ minWidth: 250 }}
          />
          {
            fitnessClientId == "0" ? (
            <TouchableOpacity>
              <MIcon
                name="add"
                size={30}
                color="#2196F3"
                style={styles.iconButton}
                onPress={ () => navigation.navigate('FitnessClientStack', {
                  screen: 'CreateFitnessClient',
                })}
              />
            </TouchableOpacity>
            ) : (
            <TouchableOpacity>
              <MCIcon
                name="account-details"
                size={30}
                color="#2196F3"
                style={styles.iconButton}
                onPress={ () => navigation.navigate('FitnessClientStack', {
                  screen: 'FitnessClientProfile',
                  params: { fitnessClientId },
                  name: selectedFitnessClient.value,
                }) }
              />
            </TouchableOpacity>
            )
          }
        </View>

        <Text>{langLoader.get('email')}</Text>
        <TextInput
          style={[styles.input, styles.inputUneditable]}
          editable={false}
          value={ selectedFitnessClient.data.email ? selectedFitnessClient.data.email : '' }
        />
        <Text>{langLoader.get('name')}</Text>
        <TextInput
          style={[styles.input, styles.inputUneditable]}
          editable={false}
          value={ ( !selectedFitnessClient.data.name.surname || !selectedFitnessClient.data.name.givename ) ? '' : `${selectedFitnessClient.data.name.surname} ${selectedFitnessClient.data.name.givename}`}
        />
        <Text>{langLoader.get('memberNumber')}</Text>
        <TextInput
          style={[styles.input, styles.inputUneditable]}
          editable={false}
          value={selectedFitnessClient.data.memberNumber}
        />
        <Text>{langLoader.get('gender')}</Text>
        <TextInput
          style={[styles.input, styles.inputUneditable]}
          editable={false}
          value={selectedFitnessClient.data.gender}
        />
        <Text>{langLoader.get('mobile')}</Text>
        <TextInput
          style={[styles.input, styles.inputUneditable]}
          editable={false}
          value={ selectedFitnessClient.data.phoneNumber ? selectedFitnessClient.data.phoneNumber : '' }
        />

        <View style={styles.titleShell}>
          <Text style={styles.title}>{langLoader.get('header-reportLanguage')}</Text>
        </View>
        <SelectView
          value={reportLanguageSelectOption.find( item => ( item.name == reportLanguage ) ).value }
          onPress={() => languageSelect.setVisible(true)}
          selecting={languageSelect.visible}
        />

        <Text style={styles.warningMessage} >{message}</Text>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Pressable
            style={ ({ pressed }) => [styles.button, styles.passiveButtion, { opacity: pressed ? 0.2 : 1 }] }
            onPress={ () => navigation.goBack() }
          >
            <Text style={styles.buttonText}>{langLoader.get('cancelButton')}</Text>
          </Pressable>
          <Pressable
            style={({ pressed }) => [styles.button, { opacity: pressed ? 0.2 : 1 }]}
            onPress={submitForm}
          >
            <Text style={styles.buttonText}>{langLoader.get('createBiotestButton')}</Text>
          </Pressable>
        </View>
      </View>
    </ScrollView>
    </SafeAreaView>
  );
};

export default AddBiotestScreen;
import * as React from 'react';
import { View, Image } from 'react-native';

import { DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';

import LanguageResourceLoader from './language';
import { LanguageContext } from './context';

import config from './config';
import styles from './styles';

import LanguageSettingScreen from './screens/language';


const langLoader = new LanguageResourceLoader('', ['en', 'zh']);

function CustomDrawerContent(props) {
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  return (
    <DrawerContentScrollView {...props}>
      <DrawerItem label={ () => <View style={[styles.view, { cursor: 'default' }]}><Image style={{ height: 50, width: 213 }} source={require('./assets/img/logo3.scale.png')} /></View> } />
      <DrawerItemList {...props} />
      <DrawerItem label={ () => <LanguageSettingScreen title={langLoader.get('language')}/> } />
      <DrawerItem
        label={langLoader.get('logout')}
        onPress={ () => {
          fetch(`${config.api_prefix}/user/logout`, { method: 'POST', credentials: 'include' })
            .then( () => {
              // setUserToken(null); // AuthContext provider
              window.location.reload(false);
            });
        }}
      />
    </DrawerContentScrollView>
  );
}

export default CustomDrawerContent;

import { StyleSheet, Pressable, Text } from 'react-native';

const SelectView = ({
  onPress = () => {},
  style = {},
  selecting = false,
  value = ''
}) => (
  <Pressable
    style={[
      styles.selectView,
      selecting ? styles.selecting : {},
      style,
    ]}
    onPress={onPress}
  >
    <Text style={styles.text}>{value}</Text>
    <Text style={[styles.text, { marginLeft: 10 }]}>&#9660;</Text>
  </Pressable>
);

const styles = StyleSheet.create({
  // Select Menu - View
  selectView: {
    flexDirection: 'row',
    justifyContent: 'space-between',

    minHeight: 40,
    width:'initial',
    padding: 10,
    margin: 12,
    elevation: 2,

    borderWidth: 1,
    borderRadius: 5,

    cursor: 'auto',
  },
  selecting: {
    borderWidth: 2
  },
  text: {
    cursor: 'default',
  }
});

export default SelectView;
  
import React, { useState } from 'react';
import { StyleSheet, View, Pressable, TouchableOpacity, Text, TextInput, Image } from 'react-native';
import LanguageResourceLoader from '../language';

import config from '../config';
import styles from '../styles';
import { IsAdminContext, AuthContext, LanguageContext } from '../context';

const langLoader = new LanguageResourceLoader('login', ['en', 'zh']);

const LoginScreen = ({ navigation }) => {
  const setAdminFlag = React.useContext(IsAdminContext).setAdminFlag;
  const languages = React.useContext(LanguageContext);
  langLoader.setPriority(languages.get);

  const setUserToken = React.useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage]= useState(null);

  const [reloadTrigger, reload] = useState(true);

  const loadLanguages = language => {
    fetch(`${config.api_prefix}/language/code/${language}`, { credentials: 'include' })
      .then((response) => response.json())
      .then((json) => {
        if(json.code == 200){
          languages.set([json.data.code, ...json.data.priority]);
        }
        else {
          // Show message
          console.log('Server Error');
        }
      })
      .catch((error) => console.error(error));
  }

  const login = () => {
    // POST request using fetch with error handling
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    };

    fetch(`${config.api_prefix}/user/login`, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        if(json.code == 200) {
          setUserToken('fake token') //maybe sessionId?
          setAdminFlag(json.data.isAdmin);
          loadLanguages(json.data.language);
        }
        else if(json.code == 405 || json.code == 403) {
          setMessage(langLoader.get('error405'));
        }
        else {
          // Error with unknow message
          setMessage(`Error ${json.code}: ${json.message}`);
        }
      })
      .catch((error) => {setMessage(`Server Error, please contact administrator.`);console.error(error);});
      // .finally(() => setLoading(false));
  };

  const submitForm = login;

  // avoid loading bug
  React.useEffect( () => {
    const i = setInterval( async () => {
      // console.log("looping");
      if( (await langLoader.getFullContent())?.default ) {
        reload (b => !b);
        clearInterval(i);
      }
    }, 200);
  }, []);

  return (
    <View style={styles.view} reload={ reloadTrigger /* Reload */ }>
      <Image
        style={loginStyles.tinyLogo}
        source={require('../assets/img/logo3.scale.png')}
      />
      <Text style={styles.header}>{langLoader.get('title')}</Text>

      <View style={styles.viewInner} >
        <Text>{langLoader.get('username')}</Text>
        <TextInput
          style={styles.input}
          autoComplete="username"
          onChange={ e => setUsername(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />
        <Text>{langLoader.get('password')}</Text>
        <TextInput
          style={styles.input}
          autoComplete="password"
          secureTextEntry={true}
          onChange={ e => setPassword(e.target.value) }
          onKeyPress={ e => (e.key === 'Enter') ? submitForm(): false }
        />

        <Text style={styles.warningMessage}>{message}</Text>
        <Pressable
          style={({ pressed }) => [styles.button, { opacity: pressed ? 0.2 : 1 }]}
          onPress={submitForm}
        >
          <Text style={styles.buttonText}>{langLoader.get('sign_in') ? langLoader.get('sign_in').toUpperCase() : ''}</Text>
        </Pressable>

        <TouchableOpacity onPress={() => navigation.navigate('ForgotPassword')} >
          <Text style={styles.linkText}>{langLoader.get('forgot_pw')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const loginStyles = StyleSheet.create({
  tinyLogo: {
    height: 150,
    width: '90%',
    resizeMode: 'contain',

    paddingBottom: 10,
  },
});

export default LoginScreen;
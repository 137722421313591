import { View, Text, ActivityIndicator } from 'react-native';

import styles from '../styles';

const LoadingScreen = props => {
  return (
    <View style={styles.view}>
      <ActivityIndicator size="large" color="#0C7036" animating={true} />
      <Text>Loading</Text>
    </View>
  );
};

export default LoadingScreen;

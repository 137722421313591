/*
// Read file not work in react-native
import fs from 'fs';

let configString = fs.readFileSync('../../.env', 'utf8');
const configArr = configString.split(/\r\n|\n|\r/);
*/
let env_config = [];/*
for(let i=0; i < arr.length; i++){
    if(arr[i][0] == '#') continue;

    const key = arr[i].split('=', 1)[0];
    let content = (arr[i] + 'E').slice(key.length - arr[i].length, -1);
    if(content[0] == content[content.length - 1] && content[0] == "'" || content[0] == '"') content = content.slice(1, -1);

    config[key] = content;
}
*/
export default {
    // Used by winston logger
    logs: {
        level: env_config.LOG_LEVEL || 'silly',
    },

    // API configs
    api_prefix: 'https://biotestplatform.sportslab.com.hk/api/v1', // env_config.BACKEND_API_URL,

    pdf_generator: 'https://biotestplatform.sportslab.com.hk/pdf', // env_config.PDF_GENERATOR_URL,

    internal: {
        adminPermission: "ADMIN",
    },
};
